import { REDUCERS } from '../../constants/reducers';

import { MeasurementTypeService } from '../../services';

export const fetchDataRequest = () => ({
  type: REDUCERS.MEASUREMENT_TYPE.FETCH_DATA_REQUEST
});

export const fetchDataSuccess = data => ({
  type: REDUCERS.MEASUREMENT_TYPE.FETCH_DATA_SUCCESS,
  payload: data
});

export const fetchDataFailure = error => ({
  type: REDUCERS.MEASUREMENT_TYPE.FETCH_DATA_FAILURE,
  payload: error
});

export const fetchMeasurementTypes = params => {
  return async dispatch => {
    dispatch(fetchDataRequest());

    try {
      const { data } = await MeasurementTypeService.fetchMeasurementTypes(params);

      dispatch(fetchDataSuccess(data));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};
