import { REDUCERS } from '../../constants/reducers';

import { NotificationService } from '../../services';

export const setNotificationList = value => ({
  type: REDUCERS.NOTIFICATION.SET_NOTIFICATION_LIST,
  value
});

export const setNotificationState = value => ({
  type: REDUCERS.NOTIFICATION.SET_NOTIFICATION_STATE,
  value
});

export const setSelectedFilters = value => ({
  type: REDUCERS.NOTIFICATION.SET_SELECTED_FILTERS,
  value
});

export const getNotifications = params => {
  return async dispatch => {
    try {
      const { data } = await NotificationService.getNotifications(params);
      const payload = {
        alerts: data?.notifications,
        alertsCount: data?.count
      };
      dispatch(setNotificationState(payload));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
