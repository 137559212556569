import { Component, createRef } from 'react';

import TreatmentsTable from '../../components/Treatments';
import Filters from '../../components/filters/index.jsx';

class Treatment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters_open: false
    };

    this.filtersClose = this.filtersClose.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);

    this.treatmentTab = createRef();
  }

  filtersUpdated(filters) {
    this.treatmentTab.current.filterData(filters);
  }

  filtersClose() {
    this.setState({ filters_open: false });
  }

  render() {
    return (
      <>
        <TreatmentsTable className="card p-2" breadcrumb="List of Livestock treatments" title="Livestock treatments" />

        <Filters
          open={this.state.filters_open}
          updateFilters={this.filtersUpdated}
          onClose={this.filtersClose}
          filter_types={['farm', 'geofence', 'label']}
        />
      </>
    );
  }
}

export default Treatment;
