import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, InputNumber, Select, Tag } from 'antd';

const customizeRequiredMark = (label, { required }) => (
  <>
    {required ? <Tag color="error">required</Tag> : <Tag color="warning">optional</Tag>}
    {label}
  </>
);

function CreateMeasurementsForm(props) {
  const { data: measurementTypes } = useSelector(state => state.measurementType);
  const [form] = Form.useForm();
  const [majorMeasurementTypeOptions, setMajorMeasurementTypeOptions] = useState([]);
  const [minorMeasurementTypeOptions, setMinorMeasurementTypeOptions] = useState([]);
  const [selectedMajorMeasurementType, setSelectedMajorMeasurementType] = useState();
  const [requiredMark, setRequiredMarkType] = useState('optional');

  if (props.formRef) props.formRef.current = form;

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => props.setSubmittable(true))
      .catch(() => props.setSubmittable(false));
  }, [values]);

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  useEffect(() => {
    if (measurementTypes) {
      const options = measurementTypes.map(measurementType => ({
        label: measurementType.major_measurement_name,
        value: measurementType.major_measurement_id
      }));

      setMajorMeasurementTypeOptions(options);
    }
  }, [measurementTypes]);

  useEffect(() => {
    if (measurementTypes && selectedMajorMeasurementType) {
      const selectedType = measurementTypes.find(type => type.major_measurement_id === selectedMajorMeasurementType);
      const options = selectedType.measure_minors?.map(measurementType => ({
        label: measurementType.minor_measurement_name,
        value: measurementType.minor_measurement_id,
        uom: measurementType.uom
      }));

      setMinorMeasurementTypeOptions(options);
    }
  }, [selectedMajorMeasurementType]);

  const handleMajorMeasurementTypeChange = newValue => {
    setSelectedMajorMeasurementType(newValue);
  };

  const handleMinorMeasurementTypeChange = newValue => {
    const selectedMinorMeasurementType = minorMeasurementTypeOptions.find(option => option.value === newValue);
    form.setFieldsValue({ uom: selectedMinorMeasurementType?.uom || '' });
  };

  return (
    <>
      <Form
        autoComplete="off"
        layout="vertical"
        variant="outlined"
        className={props.className}
        form={form}
        initialValues={{ requiredMarkValue: requiredMark }}
        onValuesChange={onRequiredTypeChange}
        requiredMark={customizeRequiredMark}>
        <Form.Item
          label="Major Measurement Type"
          name="majorMeasurementType"
          rules={[
            {
              required: true,
              message: 'Please select!'
            }
          ]}>
          <Select
            placeholder="Select Major Measurement Type"
            options={majorMeasurementTypeOptions}
            onChange={handleMajorMeasurementTypeChange}
          />
        </Form.Item>

        <Form.Item
          label="Minor Measurement Type"
          name="minorMeasurementType"
          rules={[
            {
              required: true,
              message: 'Please select!'
            }
          ]}>
          <Select
            placeholder="Select Minor Measurement Type"
            disabled={!selectedMajorMeasurementType && !minorMeasurementTypeOptions}
            options={minorMeasurementTypeOptions}
            onChange={handleMinorMeasurementTypeChange}
          />
        </Form.Item>

        <Form.Item
          label="UOM"
          name="uom"
          tooltip={{
            title: 'This is read only field. You can not change it.',
            icon: <InfoCircleOutlined />
          }}>
          <Input disabled readOnly />
        </Form.Item>

        <Form.Item
          label="Measurement"
          name="measurement"
          validateDebounce={1000}
          rules={[
            {
              required: true,
              type: 'number',
              message: 'Please input number value!'
            }
          ]}>
          <InputNumber placeholder="0" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Measurement Date"
          name="measurementDate"
          rules={[
            {
              required: true,
              message: 'Please enter Measurement date!'
            }
          ]}>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="Comments" name="comments">
          <Input.TextArea placeholder="Left your comments here" style={{ resize: 'none' }} />
        </Form.Item>
      </Form>
    </>
  );
}

export default memo(CreateMeasurementsForm);
