import { memo } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';

function AppDropdown({ className, items, label, size = 'md', handleClick }) {
  const menuProps = {
    items: items.map((item, index) => ({
      key: `${index}`,
      // TODO: delete label, handler, and disabled after actions on all pages will be as here src/views/pages/rules.jsx
      label: item.label,
      handler: item.handler,
      disabled: item.disabled,
      ...item
    })),
    onClick: handleClickOnMenuItem
  };

  // TODO: delete handleClickOnMenuItem function after actions on all pages will be as here src/views/pages/rules.jsx
  function handleClickOnMenuItem(e) {
    handleClick(e?.item?.props);
  }

  return (
    <Dropdown placement="bottomRight" trigger="click" className={className} menu={menuProps}>
      <Button color="primary" variant="outlined" size={size === 'sm' ? 'middle' : 'large'}>
        <Space>
          {label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
export default memo(AppDropdown);
