import { capitalize } from '../helpers/common';

import { BLANK_FILTER_TEXT } from '../constants/livestock';

export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id]) {
    if (typeof row[id] == 'object') {
      if (typeof row[id]['identifier'] == 'number') {
        return row[id]['identifier'] !== undefined ? String(row[id]['identifier'])?.includes(filter.value) : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]['identifier']?.toLowerCase())?.includes(filter?.value?.toLowerCase())
          : true;
      }
    } else if (typeof row[id] === 'number') {
      return row[id] !== undefined ? String(row[id])?.includes(filter?.value) : true;
    } else {
      return row[id] !== undefined ? String(row[id]?.toLowerCase())?.includes(filter?.value?.toLowerCase()) : true;
    }
  }
};

export const sortStrings = (a, b) => {
  const strA = !a ? '' : a + '';
  const strB = !b ? '' : b + '';

  return strA.localeCompare(strB);
};

export const sortDates = (a, b) => {
  if (a === null || a === undefined) return 1;
  if (b === null || b === undefined) return -1;

  const dateA = new Date(a);
  const dateB = new Date(b);
  const isValidA = !isNaN(dateA.getTime());
  const isValidB = !isNaN(dateB.getTime());

  if (!isValidA && !isValidB) return 0;
  if (!isValidA) return 1;
  if (!isValidB) return -1;

  return dateA - dateB;
};

export const sortNumbers = (a, b) => {
  const numA = Number(a);
  const numB = Number(b);

  if (isNaN(numA)) return 1;
  if (isNaN(numB)) return -1;

  return numA - numB;
};

export const getFilterOptions = (sourceData, field, field1) => {
  if (!sourceData || !field) return [];

  const keys = sourceData.map(data => (data[field] ? (field1 ? data[field][field1] : data[field]) : BLANK_FILTER_TEXT));
  const uniqueData = [...new Set(keys)];

  return uniqueData.map(data => ({
    text: capitalize(data),
    value: data
  }));
};
