import { useRef, useState } from 'react';

import MeasurementsTable from '../../components/Measurements';
import Filters from '../../components/filters/index.jsx';

function Measurements() {
  const [state, setState] = useState({
    filters: { farms: [], geofences: [], labels: [] },
    filters_open: false
  });

  const measureTab = useRef();

  function filtersUpdated(filters) {
    measureTab.current.filterData(filters);
  }

  function filtersClose() {
    setState(prevState => ({ ...prevState, filters_open: false }));
  }

  return (
    <>
      <Filters
        open={state.filters_open}
        updateFilters={filtersUpdated}
        onClose={filtersClose}
        filter_types={['farm', 'geofence', 'label']}
      />

      <MeasurementsTable className="card p-2" breadcrumb="List of Livestock measures" title="Livestock measures" />
    </>
  );
}

export default Measurements;
