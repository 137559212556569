import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';

import { DEFAULT_PAGE_SIZE } from '../../../constants/common';
import { TASK_MODAL_MODE } from '../../../constants/task';

import { setSelectedFilters } from '../../../redux/actions/task';
import { getUsers } from '../../../redux/actions/user';

import TaskModal from '../../../components/Modals/TaskModal';
import Tasks from '../../../components/Tasks';

const MyTasks = () => {
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.task);
  const [isOpenTaskModal, setOpenTaskModal] = useState(false);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    dispatch(getUsers());
    initActions();
  }, []);

  useEffect(() => {
    initActions();
  }, [selectedFilters]);

  function initActions() {
    setActions([
      {
        label: (
          <Button color="default" size="small" variant="link" style={{ padding: 0 }} onClick={() => onAddTaskClick()}>
            Add task
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!Object.values(selectedFilters)?.length}
            style={{ padding: 0 }}
            onClick={() => clearFilters()}>
            Clear all filters
          </Button>
        )
      }
    ]);
  }

  const clearFilters = () => {
    dispatch(setSelectedFilters({}));
  };

  const onAddTaskClick = () => {
    setOpenTaskModal(true);
  };

  const onSubmit = () => {
    setOpenTaskModal(false);
  };

  return (
    <>
      <div className="card">
        <Tasks
          searchPlaceholder="Search tasks"
          title="Tasks"
          actions={actions}
          queryParameters={{ limit: DEFAULT_PAGE_SIZE }}
          rowsSelectable={true}
          searchable={true}
          settings={true}
        />
      </div>
      <TaskModal
        onSubmit={onSubmit}
        isOpen={isOpenTaskModal}
        onCancel={() => setOpenTaskModal(false)}
        mode={TASK_MODAL_MODE.CREATE}
      />
    </>
  );
};

export default memo(MyTasks);
