import { memo } from 'react';

import { Radio } from 'antd';

const PrevNextButtons = ({ prevId, nextId, handlePrev, handleNext }) => {
  return (
    <Radio.Group
      buttonStyle="solid"
      size="large"
      className="d-flex flex-nowrap"
      optionType="button"
      style={{
        marginLeft: '6px',
        float: 'right'
      }}>
      <Radio.Button disabled={!prevId} onClick={handlePrev}>
        Prev
      </Radio.Button>
      <Radio.Button disabled={!nextId} onClick={handleNext}>
        Next
      </Radio.Button>
    </Radio.Group>
  );
};
export default memo(PrevNextButtons);
