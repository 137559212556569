import { memo } from 'react';

import { Tooltip } from 'antd';

function RuleSnoozeIcon() {
  return (
    <Tooltip title="Snoozed">
      <i className="fa fa-clock rule-snoozed"></i>
    </Tooltip>
  );
}

export default memo(RuleSnoozeIcon);
