import { Component } from 'react';
import Chart from 'react-c3-component';
import Select from 'react-select';
import { Card, CardBody, Col, Row } from 'reactstrap';

import 'c3/c3.css';

import { capitalize } from '../../../helpers/common';
import { sortNumbers, sortStrings } from '../../../helpers/filter';

import AppTable from '../../../components/AppTable';

class OrderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animalType: 'age_group',
      colorPattern: ['#40c4ff', '#2961ff', '#ff821c', '#FF5733', '#3B1C15', '#124908', '#493D08', '#490843']
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  async handleTypeChange(selectedOption) {
    this.setState({
      animalType: selectedOption['value']
    });
  }

  render() {
    const priorityIndex = {
      '10-15yrs': 4,
      '> 15 yrs': 5,
      '5-10 yrs': 3,
      '3-5 yrs': 2,
      '2-3 yrs': 1
    };
    const pad = { padding: '0 15px' };
    const header = { sex: 'Sex', age_group: 'Age', stock_type: 'Type' };
    const setHeader = header[this.state.animalType];
    const barColumns = [
      {
        title: <>{setHeader}</>,
        dataIndex: 'type',
        ellipsis: true,
        sortDirections: ['ascend', 'descend'],
        render: value => capitalize(value),
        sorter: (a, b) =>
          setHeader === 'Age' ? sortNumbers(priorityIndex[a.type], priorityIndex[b.type]) : sortStrings(a.type, b.type)
      },
      {
        title: 'Total',
        dataIndex: 'count',
        ellipsis: true,
        sortDirections: ['ascend', 'descend'],
        sorter: (a, b) => sortNumbers(a.count, b.count)
      }
    ];
    // var barData	= [{
    // 	'sex':'male',
    // 	'total':'45'
    // },{
    // 	'sex':'Female',
    // 	'total':'15'
    // }]
    return (
      /*--------------------------------------------------------------------------------*/
      /* Used In Dashboard-4 [General]                                                  */
      /*--------------------------------------------------------------------------------*/
      <>
        <Card className="order-widget">
          <CardBody>
            <Row>
              <Col sm="12" md="6">
                <div id="visitor" className="mt-3">
                  <Chart
                    style={{ height: '190px', width: '100%' }}
                    config={{
                      data: {
                        columns: this.props.livestocKData[this.state.animalType],
                        type: 'donut',
                        tooltip: {
                          show: true
                        }
                      },
                      donut: {
                        label: {
                          show: false
                        },
                        title: '100%',
                        width: 20
                      },
                      legend: {
                        hide: true
                      },
                      color: {
                        pattern: this.state.colorPattern
                      }
                    }}
                  />
                </div>
              </Col>
              <Col sm="12" md="6" className="gender-cols" style={pad}>
                <Row className="mt-3 gender-graph fixed-height">
                  {this.props.livestockTableData[this.state.animalType].map(function (element, index) {
                    return (
                      <Col xs="4" className="border-right gender-label" key={index}>
                        <i
                          className="fa fa-circle"
                          style={{
                            color: this.state.colorPattern[index],
                            paddingRight: '5px'
                          }}></i>
                        <span>{element.type.charAt(0).toUpperCase() + element.type.slice(1)}</span>
                      </Col>
                    );
                  }, this)}
                </Row>
                <div className="livestockselect">
                  <Select
                    name="filterType"
                    defaultValue={{ label: 'Age', value: 'age_group' }}
                    options={[
                      { value: 'sex', label: 'Sex' },
                      { value: 'stock_type', label: 'Type' },
                      { value: 'age_group', label: 'Age' }
                    ]}
                    onChange={this.handleTypeChange}
                    isMulti={false}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <div className="mx-2">
          <AppTable
            headerClass="py-2"
            baseColumns={barColumns}
            dataSource={this.props.livestockTableData[this.state.animalType]}
            // loading={this.state.isNotificationsLoading}
            pageSize={10}
            rowsSelectable={false}
            searchable={false}
            settings={false}
          />
        </div>
      </>
    );
  }
}

export default OrderStatus;
