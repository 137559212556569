export const RULE_PRIORITY_INDEX = {
  high: 2,
  medium: 1,
  low: 0,
  priority: 2,
  normal: 1,
  record: 0
};

export const RULE_PRIORITY = {
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  priority: 'High',
  normal: 'Medium',
  record: 'Low'
};
