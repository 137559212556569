import { REDUCERS } from '../../constants/reducers';

const initialState = {
  nextCount: 0,
  prevCount: 0,
  currentSelectedDate: new Date().toISOString()
};

const calendar = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.CALENDAR.SET_CURRENT_DATE: {
      return Object.assign({}, state, {
        ...state,
        currentSelectedDate: action.value
      });
    }
    case REDUCERS.CALENDAR.SET_CALENDAR_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.CALENDAR.INCREMENT_PREV_COUNT_CALENDAR: {
      return Object.assign({}, state, {
        prevCount: state.prevCount + 1
      });
    }
    case REDUCERS.CALENDAR.INCREMENT_NEXT_COUNT_CALENDAR: {
      return Object.assign({}, state, {
        nextCount: state.nextCount + 1
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { calendar };
