export const NOTIFICATION_STATUS = {
  unsnoozable: 'Cleared',
  snoozed: 'Snoozed',
  snoozable: 'New'
};

export const NOTIFICATION_ACTION_TYPE = {
  DELETE: 'DELETE',
  SNOOZE: 'SNOOZE',
  UNSNOOZE: 'UNSNOOZE'
};

export const NOTIFICATION_MODAL_CONFIG = {
  DELETE: {
    title: 'Delete Notifications',
    submitButtonTitle: 'Delete',
    message: 'Are you sure you want to delete selected Notifications? This action cannot be undone.'
  },
  SNOOZE: {
    title: 'Snooze notifications',
    submitButtonTitle: 'Snooze',
    message: 'Are you sure you want to snooze selected notifications? This action cannot be undone.'
  },
  UNSNOOZE: {
    title: 'Unsnooze notifications',
    submitButtonTitle: 'Unsnooze',
    message: 'Are you sure you want to Unsnooze selected notifications? This action cannot be undone.'
  }
};
