import { memo } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import AppModal from '../../../components/AppModal';

import './styles.scss';

function UploadModal({ isOpen, handleCSVChange, onCancel, onUpload, description }) {
  return (
    <AppModal
      isOpen={isOpen}
      confirmButtonText="Upload"
      title="XLSX Upload"
      handleCancel={onCancel}
      handleConfirm={onUpload}>
      <div className="py-4">{description}</div>
      <FormGroup>
        <Label>XLSX file</Label>
        <Input type={'file'} onChange={handleCSVChange}></Input>
      </FormGroup>
    </AppModal>
  );
}

export default memo(UploadModal);
