import { memo } from 'react';

import { capitalize } from '../../helpers/common';

import AppTag from '../../components/AppTag';

function Priority(props) {
  const colored = typeof props.isClear === 'undefined' ? true : !props.isClear;
  let priority = props.priority;
  const colors = {
    priority: colored ? 'red' : '',
    high: colored ? 'red' : '',
    normal: colored ? '#FFBF00' : '',
    medium: colored ? '#FFBF00' : '',
    low: colored ? '#CCCCCC' : '',
    record: colored ? '#CCCCCC' : ''
  };

  if (priority) {
    priority = priority.toLowerCase();

    return (
      <AppTag style={{ background: colors[priority], color: colored ? 'white' : '#565663' }}>
        {capitalize(props.priority)}
      </AppTag>
    );
  } else {
    return null;
  }
}

export default memo(Priority);
