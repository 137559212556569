import React, { memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';

import moment from 'moment';

import axios from '../../../axios';

import { DATE_FORMAT, SELECT_OPTIONS } from '../../../constants/common';
import messages from '../../../constants/messages';

import AppModal from '../../../components/AppModal';

import { successToastHandler } from '../../action_notifier';
import ConfirmationModal from '../ConfirmationModal';

const CalvRegistrationModal = ({
  isOpen,
  onCancel,
  animal,
  commonData,
  treatmentTypes,
  treatmentUOM,
  measureTypes,
  breeds
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [validationData, setValidationData] = useState({});
  const [defaultEartagManagementId, setDefaultEartagManagementId] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setFormData({});
    }

    if (isOpen && animal) {
      const defaultEartag = `${animal.eartag_management_id}_CALF_${moment().format('YYYY_MM')}`;
      setFormData({
        is_create_livestock: true,
        eartag_management_id: defaultEartag
      });
      setDefaultEartagManagementId(defaultEartag);
    }
  }, [isOpen, animal]);

  const validateForm = () => {
    setValidationData({});
    const data = {};
    if (formData.is_create_livestock) {
      if (!formData.eartag_management_id) {
        data.eartag_management_id = true;
      }
      if (!formData.sex_opts) {
        data.sex_opts = true;
      }
    }

    if (formData.is_cow_treatment) {
      if (!formData.cow_treatment_type) {
        data.cow_treatment_type = true;
      }
      if (!formData.cow_treatment) {
        data.cow_treatment = true;
      }
    }

    if (formData.is_calf_treatment) {
      if (!formData.calf_treatment_type) {
        data.calf_treatment_type = true;
      }
      if (!formData.calf_treatment) {
        data.calf_treatment = true;
      }
    }

    setValidationData({ ...data });
    return { ...data };
  };

  const onSubmit = async () => {
    const validation = validateForm();
    if (Object.keys(validation).length) return;

    if (formData.is_create_livestock) {
      try {
        setIsLoading(true);
        const animalCreateData = {
          eartag_management_id: formData.eartag_management_id,
          specie: animal.specie.name,
          // breed: animal.breed.code,
          breed: formData.breed,
          sex: formData.sex_opts,
          colour: formData.colour,
          dob_at: new Date(),
          farm_id: animal.farm_id,
          dam_id: animal.id
        };

        let newAnimal = await axios.post('animals', animalCreateData, {
          skipAlert: true
        });
        newAnimal = newAnimal.data;
        successToastHandler(messages.NEW_LIVESTOCK_FOR_CALF_RECORD);
        const promises = [
          createCalfBirthBreeding(newAnimal),
          createCalfWeightMeasurement(newAnimal),
          creatPostCalvingMeasurement(),
          creatEbvMeasurement(),
          creatCowTreatment(),
          creatCalfTreatment(newAnimal)
        ];

        try {
          await Promise.all(promises);
          setIsLoading(false);
          onCancel();
        } catch (e) {
          setIsLoading(false);
          onCancel();
        }
      } catch (e) {
        setIsLoading(false);
      }
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const createCalfBirthBreeding = async newAnimal => {
    const birthEventType = commonData.event_type_id.find(item => {
      return item.label === 'Birth';
    });
    const sucklingBreedingStatus = commonData.breeding_status_id.find(item => {
      return item.label === 'Suckling';
    });

    const newBreedingData = {
      breeding_status_id: sucklingBreedingStatus.value,
      event_start_date: moment().format(DATE_FORMAT.ISO_8601),
      event_end_date: moment().format(DATE_FORMAT.ISO_8601),
      event_type_id: birthEventType.value,
      livestock_id: newAnimal.id
    };
    return axios
      .post('/breeding/store', newBreedingData, {
        skipAlert: true
      })
      .then(() => {
        successToastHandler(messages.NEW_BREEDING_EVENT_BIRTH_FOR_CALF);
      });
  };

  const createCalfWeightMeasurement = async newAnimal => {
    if (formData.calf_weight) {
      const weightMajorMeasureType = measureTypes.find(item => item.major_measurement_code === 'WGT');
      const calvingMinorMeasureType = weightMajorMeasureType.measure_minors.find(
        item => item.minor_measurement_code === 'CLC'
      );

      const newMeasureData = {
        major_measure_id: weightMajorMeasureType.major_measurement_id,
        minor_measure_id: calvingMinorMeasureType.minor_measurement_id,
        measurement_date: moment().format(DATE_FORMAT.ISO_8601),
        uom: 'Kg',
        measurement: formData.calf_weight,
        livestock_id: newAnimal.id
      };
      return axios
        .post('/measures', newMeasureData, {
          skipAlert: true
        })
        .then(() => {
          successToastHandler(messages.NEW_MEASUREMENT_EVENT_CALVING_WEIGHT_FOR_CALF);
        });
    }
    return new Promise.resolve();
  };

  const creatPostCalvingMeasurement = async () => {
    if (formData.post_calving_weight) {
      const weightMajorMeasureType = measureTypes.find(item => item.major_measurement_code === 'WGT');
      const postCalvingMinorMeasureType = weightMajorMeasureType.measure_minors.find(
        item => item.minor_measurement_code === 'PCC'
      );

      const newMeasureData = {
        major_measure_id: weightMajorMeasureType.major_measurement_id,
        minor_measure_id: postCalvingMinorMeasureType.minor_measurement_id,
        measurement_date: moment().format(DATE_FORMAT.ISO_8601),
        uom: 'Kg',
        measurement: formData.post_calving_weight,
        livestock_id: animal.id
      };
      return axios
        .post('/measures', newMeasureData, {
          skipAlert: true
        })
        .then(() => {
          successToastHandler(messages.NEW_MEASUREMENT_EVENT_POST_CALVING_WEIGHT_FOR_CALF);
        });
    }

    return new Promise.resolve();
  };

  const creatEbvMeasurement = async () => {
    if (formData.calving_ease) {
      const weightMajorMeasureType = measureTypes.find(item => item.major_measurement_code === 'EBVC');
      const postCalvingMinorMeasureType = weightMajorMeasureType.measure_minors.find(
        item => item.minor_measurement_code === 'CLI'
      );

      const newMeasureData = {
        major_measure_id: weightMajorMeasureType.major_measurement_id,
        minor_measure_id: postCalvingMinorMeasureType.minor_measurement_id,
        measurement_date: moment().format(DATE_FORMAT.ISO_8601),
        measurement: formData.calving_ease,
        livestock_id: animal.id
      };
      return axios
        .post('/measures', newMeasureData, {
          skipAlert: true
        })
        .then(() => {
          successToastHandler(messages.NEW_MEASUREMENT_EVENT_CALVING_EASE_FOR_COW);
        });
    }
    return new Promise.resolve();
  };

  const creatCowTreatment = async () => {
    if (formData.is_cow_treatment) {
      const newTreatmentData = {
        treatment_type_id: formData.cow_treatment_type,
        treatment_date: moment().format(DATE_FORMAT.ISO_8601),
        symptoms: formData.cow_symptoms,
        treatment: formData.cow_treatment,
        medication: formData.cow_medication,
        dosage_uom: formData.cow_treatment_uom,
        dosage_amount: formData.cow_dosage_amount,
        vet: formData.cow_vet,
        comments: formData.cow_comment,
        cost: formData.cow_cost || 0,
        repeat_date: moment(new Date(formData.cow_repeat_date)).format(DATE_FORMAT.ISO_8601),
        review_date: moment(new Date(formData.cow_review_date)).format(DATE_FORMAT.ISO_8601),
        livestock_id: animal.id
      };
      return axios
        .post('/treatment/store', newTreatmentData, {
          skipAlert: true
        })
        .then(() => {
          successToastHandler(messages.NEW_TREATMENT_FOR_COW);
        });
    }
    return new Promise.resolve();
  };

  const creatCalfTreatment = async newAnimal => {
    if (formData.is_calf_treatment) {
      const newTreatmentData = {
        treatment_type_id: formData.calf_treatment_type,
        treatment_date: moment().format(DATE_FORMAT.ISO_8601),
        symptoms: formData.calf_symptoms,
        treatment: formData.calf_treatment,
        medication: formData.calf_medication,
        dosage_uom: formData.calf_treatment_uom,
        dosage_amount: formData.calf_dosage_amount,
        vet: formData.calf_vet,
        comments: formData.calf_comment,
        cost: formData.calf_cost || 0,
        repeat_date:
          formData.calf_repeat_date && moment(new Date(formData.calf_repeat_date)).format(DATE_FORMAT.ISO_8601),
        review_date:
          formData.calf_review_date && moment(new Date(formData.calf_review_date)).format(DATE_FORMAT.ISO_8601),
        livestock_id: newAnimal.id
      };
      return axios
        .post('/treatment/store', newTreatmentData, {
          skipAlert: true
        })
        .then(() => {
          successToastHandler(messages.NEW_TREATMENT_FOR_CALF);
        });
    }
    return new Promise.resolve();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isOpenConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        title={'Create Animal'}
        description={'No additional records will be created'}
        onSubmit={() => {
          setOpenConfirmationModal(false);
          onCancel();
        }}
        submitText="Ok"
      />

      <AppModal
        isOpen={isOpen}
        confirmButtonText={isLoading ? 'Loading' : 'Register'}
        confirmButtonDisabled={isLoading}
        title="Register Calf"
        handleCancel={() => setOpenConfirmationModal(true)}
        handleConfirm={() => onSubmit()}>
        <div className="py-4">
          <Form>
            <FormGroup row>
              <Label sm="8">Create New Livestock record for the calf:</Label>
              <Col
                sm="4"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}>
                <Input
                  defaultChecked
                  type="checkbox"
                  onChange={e =>
                    setFormData({
                      ...formData,
                      eartag_management_id: defaultEartagManagementId,
                      sex_opts: undefined,
                      colour: '',
                      calf_weight: '',
                      breed: '',
                      is_create_livestock: e.target.checked
                    })
                  }
                />
              </Col>
            </FormGroup>
            {formData.is_create_livestock && (
              <>
                <FormGroup row>
                  <Label sm="4">Mgmt Tag ID *</Label>
                  <Col sm="8">
                    <Input
                      invalid={validationData.eartag_management_id}
                      type="text"
                      defaultValue={formData.eartag_management_id}
                      onChange={e => {
                        setFormData({
                          ...formData,
                          eartag_management_id: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Calf Sex*</Label>
                  <Col sm="8">
                    <Select
                      className={
                        validationData.sex_opts
                          ? 'is-invalid select-form-control form-control'
                          : 'select-form-control form-control'
                      }
                      classNamePrefix="react-select"
                      value={SELECT_OPTIONS.GENDER.find(({ value }) => value === formData.sex_opts)}
                      onChange={({ value }) => {
                        setFormData({
                          ...formData,
                          sex_opts: value
                        });
                      }}
                      options={SELECT_OPTIONS.GENDER}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Breed</Label>
                  <Col sm="8">
                    <Select
                      value={breeds.find(({ value }) => value === formData.breed)}
                      onChange={({ value }) => {
                        setFormData({
                          ...formData,
                          breed: value
                        });
                      }}
                      options={breeds}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Calf Colour</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          colour: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
              </>
            )}

            <FormGroup row>
              <Label sm="8">Additional Cow Records:</Label>
              <Col
                sm="4"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}>
                <Input
                  type="checkbox"
                  onChange={e =>
                    setFormData({
                      ...formData,
                      post_calving_weight: null,
                      calving_ease: null,
                      is_additional_records: e.target.checked
                    })
                  }
                />
              </Col>
            </FormGroup>
            {formData.is_additional_records && (
              <>
                <FormGroup row>
                  <Label sm="4">Cow - Post Calving Weight</Label>
                  <Col sm="8">
                    <Input
                      type="number"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          post_calving_weight: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">EBV Calving Ease</Label>
                  <Col sm="8">
                    <Input
                      type="number"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          calving_ease: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
              </>
            )}

            <FormGroup row>
              <Label sm="8">Cow Treatment:</Label>
              <Col
                sm="4"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}>
                <Input
                  type="checkbox"
                  onChange={e =>
                    setFormData({
                      ...formData,
                      cow_treatment_type: null,
                      cow_symptoms: null,
                      cow_treatment: null,
                      cow_medication: null,
                      cow_treatment_uom: null,
                      cow_dosage_amount: null,
                      cow_vet: null,
                      cow_cost: null,
                      cow_comment: null,
                      cow_repeat_date: null,
                      cow_review_date: null,
                      is_cow_treatment: e.target.checked
                    })
                  }
                />
              </Col>
            </FormGroup>
            {formData.is_cow_treatment && (
              <>
                <FormGroup row>
                  <Label sm="4">Treatment Type*</Label>
                  <Col sm="8">
                    <Select
                      className={
                        validationData.cow_treatment_type
                          ? 'is-invalid select-form-control form-control'
                          : 'select-form-control form-control'
                      }
                      classNamePrefix="react-select"
                      value={treatmentTypes.find(({ value }) => value === formData.cow_treatment_type)}
                      onChange={({ value }) => {
                        setFormData({
                          ...formData,
                          cow_treatment_type: value
                        });
                      }}
                      options={treatmentTypes}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Symptoms</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          cow_symptoms: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Treatment*</Label>
                  <Col sm="8">
                    <Input
                      invalid={validationData.cow_treatment}
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          cow_treatment: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Medication</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          cow_medication: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Dosage UoM</Label>
                  <Col sm="8">
                    <Select
                      value={treatmentUOM.find(({ value }) => value === formData.cow_treatment_uom)}
                      onChange={({ value }) => {
                        setFormData({
                          ...formData,
                          cow_treatment_uom: value
                        });
                      }}
                      options={treatmentUOM}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Dosage Amount</Label>
                  <Col sm="8">
                    <Input
                      type="number"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          cow_dosage_amount: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">VET</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          cow_vet: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Cost</Label>
                  <Col sm="8">
                    <Input
                      type="number"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          cow_cost: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Comments</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          cow_comment: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Repeat Date</Label>
                  <Col sm="8">
                    <DatePicker
                      selected={formData.cow_repeat_date}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      onChange={e =>
                        setFormData({
                          ...formData,
                          cow_repeat_date: e
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Review Date</Label>
                  <Col sm="8">
                    <DatePicker
                      selected={formData.cow_review_date}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      onChange={e =>
                        setFormData({
                          ...formData,
                          cow_review_date: e
                        })
                      }
                    />
                  </Col>
                </FormGroup>
              </>
            )}

            <FormGroup row>
              <Label sm="8">Calf Treatment:</Label>
              <Col
                sm="4"
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}>
                <Input
                  type="checkbox"
                  onChange={e =>
                    setFormData({
                      ...formData,
                      calf_treatment_type: null,
                      calf_symptoms: null,
                      calf_treatment: null,
                      calf_medication: null,
                      calf_treatment_uom: null,
                      calf_dosage_amount: null,
                      calf_vet: null,
                      calf_cost: null,
                      calf_comment: null,
                      calf_repeat_date: null,
                      calf_review_date: null,
                      is_calf_treatment: e.target.checked
                    })
                  }
                />
              </Col>
            </FormGroup>
            {formData.is_calf_treatment && (
              <>
                <FormGroup row>
                  <Label sm="4">Treatment Type*</Label>
                  <Col sm="8">
                    <Select
                      className={
                        validationData.calf_treatment_type
                          ? 'is-invalid select-form-control form-control'
                          : 'select-form-control form-control'
                      }
                      classNamePrefix="react-select"
                      value={treatmentTypes.find(({ value }) => value === formData.calf_treatment_type)}
                      onChange={({ value }) => {
                        setFormData({
                          ...formData,
                          calf_treatment_type: value
                        });
                      }}
                      options={treatmentTypes}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Symptoms</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          calf_symptoms: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Treatment*</Label>
                  <Col sm="8">
                    <Input
                      invalid={validationData.calf_treatment}
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          calf_treatment: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Medication</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          calf_medication: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Dosage UoM</Label>
                  <Col sm="8">
                    <Select
                      value={treatmentUOM.find(({ value }) => value === formData.calf_treatment_uom)}
                      onChange={({ value }) => {
                        setFormData({
                          ...formData,
                          calf_treatment_uom: value
                        });
                      }}
                      options={treatmentUOM}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Dosage Amount</Label>
                  <Col sm="8">
                    <Input
                      type="number"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          calf_dosage_amount: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">VET</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          calf_vet: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Cost</Label>
                  <Col sm="8">
                    <Input
                      type="number"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          calf_cost: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Comments</Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      onChange={e => {
                        setFormData({
                          ...formData,
                          calf_comment: e.target.value
                        });
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Repeat Date</Label>
                  <Col sm="8">
                    <DatePicker
                      selected={formData.calf_repeat_date}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      onChange={e =>
                        setFormData({
                          ...formData,
                          calf_repeat_date: e
                        })
                      }
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm="4">Review Date</Label>
                  <Col sm="8">
                    <DatePicker
                      selected={formData.calf_review_date}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      onChange={e =>
                        setFormData({
                          ...formData,
                          calf_review_date: e
                        })
                      }
                    />
                  </Col>
                </FormGroup>
              </>
            )}
          </Form>
        </div>
      </AppModal>
    </>
  );
};

export default memo(CalvRegistrationModal);
