import { memo, useEffect, useState } from 'react';

import { Checkbox, DatePicker, Form, Input, InputNumber, Select, Tag } from 'antd';

import { DATE_FORMAT } from '../../constants/common';

const customizeRequiredMark = (label, { required }) => (
  <>
    {required ? <Tag color="error">required</Tag> : <Tag color="warning">optional</Tag>}
    {label}
  </>
);

function CreateBreedingForm(props) {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState('optional');

  if (props.formRef) props.formRef.current = form;

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => props.setSubmittable(true))
      .catch(() => props.setSubmittable(false));
  }, [values]);

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const handleCheckboxChange = event => {
    form.setFieldsValue({ is_pregnant: event.target.checked ? 1 : 0 });
  };

  return (
    <div className="px-2" style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <Form
        autoComplete="off"
        layout="vertical"
        variant="outlined"
        className={props.className}
        form={form}
        initialValues={{ requiredMarkValue: requiredMark }}
        onValuesChange={onRequiredTypeChange}
        requiredMark={customizeRequiredMark}>
        <Form.Item
          label="Event Type"
          name="event_type_id"
          rules={[
            {
              required: true,
              message: 'Please select!'
            }
          ]}>
          <Select placeholder="Select Event Type" options={props?.data?.event_type_id} />
        </Form.Item>

        <Form.Item
          label="Start Date"
          name="event_start_date"
          rules={[{ required: true, message: 'Please enter Start date!' }]}>
          <DatePicker format={DATE_FORMAT.DATE} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="End Date" name="event_end_date">
          <DatePicker format={DATE_FORMAT.DATE} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="Expected Calving Date" name="expected_calving_date">
          <DatePicker format={DATE_FORMAT.DATE} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="Event Description" name="event_description">
          <Input.TextArea placeholder="Left description here" style={{ resize: 'none' }} />
        </Form.Item>

        <Form.Item label="Is Pregnant" name="is_pregnant" valuePropName="checked">
          <Checkbox onChange={handleCheckboxChange} />
        </Form.Item>

        <Form.Item label="Sire ID" name="sire_id">
          <Select placeholder="Select Sire ID" options={props?.data?.sire_id} />
        </Form.Item>

        <Form.Item label="Calf ID" name="calf_id">
          <Select placeholder="Select Calf ID" options={props?.data?.calf_id} />
        </Form.Item>

        <Form.Item label="Mating Type" name="mating_type_id">
          <Select placeholder="Select Mating Type" options={props?.data?.mating_type_id} />
        </Form.Item>

        <Form.Item label="Straws" name="straws">
          <Input />
        </Form.Item>

        <Form.Item label="Straw Ref" name="straw_ref">
          <Input />
        </Form.Item>

        <Form.Item
          label="Cost"
          name="cost"
          validateDebounce={1000}
          rules={[
            {
              type: 'number',
              message: 'Please input number value!'
            }
          ]}>
          <InputNumber placeholder="0" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="Breeding Status" name="breeding_status_id">
          <Select placeholder="Select Breeding Status" options={props?.data?.breeding_status_id} />
        </Form.Item>

        <Form.Item label="Calving Performance" name="calving_performance">
          <Input />
        </Form.Item>

        <Form.Item label="Maternity Performance" name="maternity_performance">
          <Input />
        </Form.Item>

        <Form.Item label="Comments" name="comments">
          <Input.TextArea placeholder="Left your comments here" style={{ resize: 'none' }} />
        </Form.Item>

        <Form.Item label="Breeding Plan" name="breeding_plan">
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
}

export default memo(CreateBreedingForm);
