import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import 'moment/locale/en-gb';

import { DATE_FORMAT } from '../../constants/common';
import { EVENT_MODAL_MODE } from '../../constants/event';

import { getAllEvents, setCurrentEvent, setEventQueryParams } from '../../redux/actions/event';

import EventModal from '../Modals/EventModal';
import CustomAgenda from './CustomAgenda';
import CustomToolbar from './CustomToolbar';
import { AGENDA } from './constants';
import './styles.scss';

const localizer = momentLocalizer(moment);

function CustomCalendar({ step = 30, queryParameters = {} }) {
  const { components, views } = useMemo(
    () => ({
      components: {
        customAgenda: CustomAgenda
      },
      views: [Views.MONTH, Views.WEEK, Views.DAY, AGENDA]
    }),
    []
  );
  const dispatch = useDispatch();
  const eventState = useSelector(state => state.event);
  const { currentSelectedDate } = useSelector(state => state.calendar);
  const [currentView, setCurrentView] = useState(Views.WEEK);
  const [isOpenEventModal, setOpenEventModal] = useState(false);

  const getEvents = (startDate = moment().startOf('month'), endDate = moment().endOf('month')) => {
    const parsedDate = endDate.format(DATE_FORMAT.DATETIME);
    const params = {
      endDate: moment.utc(parsedDate, DATE_FORMAT.DATETIME).toISOString(),
      ...queryParameters
    };
    dispatch(setEventQueryParams(params));
    dispatch(getAllEvents(params));
  };

  useEffect(() => {
    getEvents();
  }, []);

  const formatEvents = useCallback(events => {
    return events.map(event => ({
      ...event,
      title: event.title,
      start: new Date(event.start_at),
      end: new Date(event.end_at)
    }));
  }, []);

  const onView = view => {
    setCurrentView(view);
  };

  const onNavigate = (date, interval) => {
    const startDate = interval === AGENDA ? moment(date) : moment(date).startOf('month');
    const endDate = interval === AGENDA ? moment(date).add(1, 'month') : moment(date).endOf('month');

    getEvents(startDate, endDate);
  };

  const onSubmit = (event, action) => {};

  const onSelectEvent = event => {
    const formattedEvent = { ...event };
    delete formattedEvent.titleWithTime;
    delete formattedEvent.end;
    delete formattedEvent.start;
    dispatch(setCurrentEvent(formattedEvent));
    setOpenEventModal(true);
  };

  return (
    <div className="calendar-component p-2">
      <CustomToolbar onView={onView} onNavigate={onNavigate} />
      {currentView === AGENDA ? (
        <CustomAgenda events={formatEvents(eventState.events)} onView={onView} />
      ) : (
        <Calendar
          date={currentSelectedDate}
          view={currentView}
          components={components}
          events={formatEvents(eventState.events)}
          localizer={localizer}
          showMultiDayTimes
          step={step}
          views={views}
          toolbar={false}
          onNavigate={onNavigate}
          onSelectEvent={onSelectEvent}
          onView={onView}
        />
      )}

      <EventModal
        isOpen={isOpenEventModal}
        onCancel={() => setOpenEventModal(false)}
        onSubmit={onSubmit}
        mode={EVENT_MODAL_MODE.VIEW}
      />
    </div>
  );
}

export default memo(CustomCalendar);
