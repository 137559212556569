import React, { useEffect, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Radio } from 'antd';
import classnames from 'classnames';
import moment from 'moment';

import { setCurrentSelectedDay } from '../../redux/actions/calendar';

import { AGENDA, TOOLBAR_OPTIONS } from './constants';

function CustomToolbar(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentSelectedDate } = useSelector(state => state.calendar);
  const [viewState, setViewState] = useState(Views.WEEK);

  const getCustomToolbar = () => {
    const calendarState = useSelector(state => state.calendar);

    const goToBack = () => {
      const date = new Date(currentSelectedDate);
      const newDate =
        viewState === Views.MONTH
          ? new Date(date.getFullYear(), date.getMonth() - 1, 1)
          : viewState === Views.WEEK
          ? new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7, 1)
          : new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 1);

      props.onNavigate(newDate, viewState);
      dispatch(setCurrentSelectedDay(newDate.toISOString()));
    };

    const goToNext = () => {
      const date = new Date(currentSelectedDate);
      const newDate =
        viewState === Views.MONTH
          ? new Date(date.getFullYear(), date.getMonth() + 1, 1)
          : viewState === Views.WEEK
          ? new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7, 1)
          : new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 1);

      props.onNavigate(newDate, viewState);
      dispatch(setCurrentSelectedDay(newDate.toISOString()));
    };

    useEffect(() => {
      if (calendarState.prevCount) {
        goToBack();
      }
    }, [calendarState.prevCount]);

    useEffect(() => {
      if (calendarState.nextCount) {
        goToNext();
      }
    }, [calendarState.nextCount]);

    const goToDayView = () => {
      const today = new Date();
      dispatch(setCurrentSelectedDay(today.toISOString()));
      props.onView(Views.DAY);
      setViewState(Views.DAY);
    };

    const goToWeekView = () => {
      const today = new Date();
      dispatch(setCurrentSelectedDay(today.toISOString()));
      props.onView(Views.WEEK);
      setViewState(Views.WEEK);
    };

    const goToMonthView = () => {
      const today = new Date();
      dispatch(setCurrentSelectedDay(today.toISOString()));
      props.onView(Views.MONTH);
      setViewState(Views.MONTH);
    };

    const goToAgendaView = () => {
      props.onView('customAgenda');
      setViewState('customAgenda');
    };

    const goToToday = () => {
      const today = new Date();
      dispatch(setCurrentSelectedDay(today.toISOString()));
      props.date?.setMonth(today.getMonth());
      props.date?.setYear(today.getFullYear());
      props.date?.setDate(today.getDate());
      props.onNavigate('current');
    };

    const goToBackYear = () => {
      const mDate = props.date;
      const newDate = new Date(mDate.getFullYear() - 1, 1);
      props.onNavigate('prev', newDate);
    };

    const goToNextYear = () => {
      const mDate = props.date;
      const newDate = new Date(mDate.getFullYear() + 1, 1);
      props.onNavigate('next', newDate);
    };

    const month = () => {
      const date = moment(currentSelectedDate);
      const month = date.format('MMMM');
      const day = date.format('D');

      return (
        <p className="month">
          <i className="far fa-calendar"></i> <span>{`${month} ${day}`}</span>
        </p>
      );
    };

    const year = () => {
      const date = moment(props.date);
      const year = date.format('YYYY');

      return (
        <span className="rbc-btn-group">
          {viewState === 'month' && (
            <button type="button" onClick={goToBackYear}>
              <span className="prev-icon">&#8249;&#8249;</span>
            </button>
          )}
          <span className="rbc-toolbar-label">{year}</span>
          {viewState === 'month' && (
            <button type="button" onClick={goToNextYear}>
              <span className="prev-icon">&#8250;&#8250;</span>
            </button>
          )}
        </span>
      );
    };

    const day = () => {
      let view = viewState;
      const date = moment(props.date);
      let day;
      if (view === 'day') {
        day = date.format('ddd') + ' ' + date.format('Do');
      }
      return <span className="rbc-toolbar-label">{day}</span>;
    };

    const onChange = ({ target: { value } }) => {
      setViewState(value);
      switch (value) {
        case 'today':
          return goToToday();
        case 'day':
          return goToDayView();
        case 'week':
          return goToWeekView();
        case 'month':
          return goToMonthView();
        case 'customAgenda':
          return goToAgendaView();
      }
    };

    const options = [
      {
        label: 'Today',
        value: 'today',
        disabled: moment(new Date(currentSelectedDate)).isSame(moment(new Date()), 'day')
      },
      ...TOOLBAR_OPTIONS
    ];

    return (
      <div className="d-flex justify-content-center">
        <div className="rbc-toolbar-item-2">
          {month()}

          {location.pathname !== '/dashboard' && (
            <div className={classnames({ 'mb-2': viewState !== AGENDA })}>
              <Radio.Group
                size="large"
                options={options}
                onChange={onChange}
                value={viewState}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return <>{getCustomToolbar()}</>;
}

export default CustomToolbar;
