export default function GatewayIcon(props) {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title />
      <g data-name="Layer 2" id="Layer_2">
        <path
          fill="currentColor"
          d="M2,12h9v2H9v2H2v2H9v2h6V18h7V16H15V14H13V12h9V4H2Zm11,6H11V16h2ZM8,7h2V9H8ZM5,7H7V9H5Z"
        />
      </g>
    </svg>
  );
}
