import axios from '../../axios';

import { formatDate } from '../../helpers/date';
import { getQueryNameByRelationType } from '../../helpers/relation';

import { REDUCERS } from '../../constants/reducers';

import { TaskService } from '../../services';

export const setSelectedFilters = value => ({
  type: REDUCERS.TASK.SET_SELECTED_FILTERS,
  value
});

export const setCurrentTask = value => ({
  type: REDUCERS.TASK.SET_CURRENT_TASK,
  value
});

export const setTaskState = value => ({
  type: REDUCERS.TASK.SET_TASK_STATE,
  value
});

export const setTaskQueryParams = value => ({
  type: REDUCERS.TASK.SET_TASK_QUERY_PARAMS,
  value
});

export const fetchDataRequest = () => ({
  type: REDUCERS.TASK.FETCH_DATA_REQUEST
});

export const fetchDataSuccess = data => ({
  type: REDUCERS.TASK.FETCH_DATA_SUCCESS,
  payload: data
});

export const fetchDataFailure = error => ({
  type: REDUCERS.TASK.FETCH_DATA_FAILURE,
  payload: error
});

export const getAllTasks = params => {
  return async dispatch => {
    dispatch(fetchDataRequest());

    try {
      const { data } = await TaskService.getAllTasks(params);

      dispatch(fetchDataSuccess(data));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};

export const getTaskById = id => {
  return async dispatch => {
    try {
      const { data } = await TaskService.getTaskById(id);
      const task = {
        ...data,
        remindDate: formatDate(data.remind_at),
        dueDate: formatDate(data.due_date),
        userIds: data.users?.map(user => user.id),
        userEmails: data.users?.map(user => user.email)
      };
      dispatch(setCurrentTask(task));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNewTask = payload => {
  return async () => {
    try {
      const { data } = await TaskService.createNewTask(payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateTask = (id, payload) => {
  return async () => {
    try {
      const { data } = await TaskService.updateTask(id, payload);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteTask = id => {
  return async dispatch => {
    try {
      const { data } = await TaskService.deleteTask(id);
      dispatch(setCurrentTask({}));
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const sendRelationQuery = relationType => {
  return async () => {
    try {
      const queryName = getQueryNameByRelationType(relationType);
      let response = await axios.get(queryName);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const acceptTask = id => {
  return async () => {
    try {
      const { data } = await TaskService.acceptTask(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const declineTask = id => {
  return async () => {
    try {
      const { data } = await TaskService.declineTask(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};
