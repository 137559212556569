import React, { useEffect } from 'react';

const composeCellValue = ({ state, cellInfo, cellKey, isAddress }) => {
  if (isAddress) {
    return state.tableData?.[cellInfo.index]?.address?.[cellKey] ?? '';
  }

  return state.tableData?.[cellInfo.index]?.[cellInfo.column.id] ?? '';
};

const EditableInputCell = ({
  cellInfo,
  state,
  editableRowIndex,
  onInputChange,
  editableKeyToFocus,
  cellKey,
  isAddress = false
}) => {
  const id = cellInfo.original.id;
  const cellValue = composeCellValue({ state, cellInfo, cellKey, isAddress });

  const handleClickOutside = event => {
    if (!editableKeyToFocus.current) {
      return;
    }

    const target = event.target;
    if (!target.closest('.form-control')) {
      editableKeyToFocus.current = null;
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  if (editableRowIndex.includes(id)) {
    return (
      <input
        placeholder=""
        key={cellKey}
        type="text"
        className="form-control"
        value={cellValue}
        onChange={e => {
          onInputChange(cellInfo, e, isAddress);
          editableKeyToFocus.current = cellKey;
        }}
        autoFocus={cellKey === editableKeyToFocus.current}
      />
    );
  }

  return <span>{cellInfo.value}</span>;
};

export default EditableInputCell;
