import axios from '../../axios';

const service = {
  async getAllTasks(params) {
    return axios({
      method: 'get',
      url: '/tasks',
      params
    });
  },

  async getTaskById(id) {
    return axios({
      method: 'get',
      url: `/tasks/${id}`
    });
  },

  async createNewTask(payload) {
    return axios({
      method: 'post',
      url: '/tasks',
      data: payload
    });
  },

  async deleteTask(id) {
    return axios({
      method: 'delete',
      url: `/tasks/${id}`
    });
  },

  async updateTask(id, payload) {
    return axios({
      method: 'put',
      url: `/tasks/${id}`,
      data: payload
    });
  },

  async acceptTask(id) {
    return axios({
      method: 'post',
      url: `/tasks/${id}/accept`
    });
  },

  async declineTask(id) {
    return axios({
      method: 'post',
      url: `/tasks/${id}/decline`
    });
  }
};

export default service;
