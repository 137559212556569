import React, { memo } from 'react';

import { Button, Modal } from 'antd';

import './styles.scss';

function AppModal({
  cancelButtonHidden = false,
  cancelButtonText = 'Cancel',
  children,
  confirmButtonColor = 'primary',
  confirmButtonDisabled = false,
  confirmButtonHidden = false,
  confirmButtonText,
  isOpen,
  loading,
  title,
  handleCancel,
  handleConfirm
}) {
  return (
    <Modal
      open={isOpen}
      title={title}
      onOk={handleConfirm}
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          size="large"
          style={{ display: cancelButtonHidden ? 'none' : 'inline-block' }}
          onClick={handleCancel}>
          {cancelButtonText}
        </Button>,
        <Button
          key="submit"
          size="large"
          variant="solid"
          color={confirmButtonColor}
          disabled={confirmButtonDisabled}
          loading={loading}
          style={{ display: confirmButtonHidden ? 'none' : 'inline-block' }}
          onClick={handleConfirm}>
          {confirmButtonText}
        </Button>
      ]}>
      {children}
    </Modal>
  );
}

export default memo(AppModal);
