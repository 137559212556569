import axios from '../../axios';

const service = {
  createTreatment(data) {
    return axios({ method: 'post', url: 'treatment/store', data });
  },

  createTreatments(data) {
    return axios({ method: 'post', url: 'treatment/store/bulk', data: { treatmentData: data } });
  },

  fetchTreatments(params) {
    return axios({ method: 'get', url: 'treatment/index', params });
  },

  fetchTreatmentUOM() {
    return axios({ method: 'get', url: 'treatment_uom' });
  },

  fetchTreatmentTypes() {
    return axios({ method: 'get', url: 'treatment_types' });
  },

  deleteTreatments(ids) {
    return axios({ method: 'delete', url: `treatment/destroy_bulk`, data: { ids } });
  }
};

export default service;
