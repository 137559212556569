export default function TagIcon(props) {
  return (
    <svg
      {...props}
      enableBackground="new 0 0 512 512"
      height="15px"
      version="1.1"
      viewBox="0 0 512 512"
      width="15px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <g>
            <path
              clipRule="evenodd"
              d="M415.698,192.641c-8.379-21.068-21.236-40.268-38.577-57.6      l36.309-36.364c26.971,26.989,45.555,57.456,55.745,91.404c6.226,20.683,9.349,42.655,9.367,65.919v0.06      c-0.008,34.011-6.66,65.254-19.957,93.729c-10.701,23.024-25.735,44.24-45.097,63.651l-36.427-36.422      c13.297-13.305,23.948-27.696,31.945-43.171c12.119-23.434,18.17-49.363,18.156-77.788V256      C427.19,233.287,423.372,212.168,415.698,192.641z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </g>
          <g>
            <path
              clipRule="evenodd"
              d="M351.69,160.99c8.768,8.825,16.062,18.27,21.88,28.334h0.06      c11.449,19.798,17.17,41.983,17.165,66.56V256c-0.004,25.516-6.152,48.478-18.447,68.889h-0.057      c-5.676,9.334-12.638,18.139-20.892,26.414v-0.06l-0.057,0.06l-36.427-36.423c2.371-2.448,4.562-4.95,6.576-7.505v-0.06      c11.828-14.672,17.763-31.779,17.807-51.315v-0.116c-0.019-22.938-8.124-42.506-24.323-58.705l36.483-36.538      C351.541,160.752,351.618,160.868,351.69,160.99z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </g>
          <g>
            <path
              clipRule="evenodd"
              d="M225.829,225.571c8.342-8.35,18.427-12.519,30.259-12.509      c11.851-0.01,21.956,4.159,30.316,12.509l0.059,0.059c8.313,8.348,12.463,18.433,12.451,30.254V256      c-0.016,11.798-4.187,21.863-12.51,30.197c-0.03,0.027-0.048,0.068-0.058,0.116c-8.364,8.272-18.447,12.402-30.259,12.394      c-11.817,0.009-21.884-4.142-30.201-12.452l-0.058-0.058c-8.311-8.317-12.481-18.365-12.511-30.138c0-0.06,0-0.117,0-0.176      c-0.012-11.787,4.121-21.854,12.395-30.196C225.747,225.653,225.786,225.615,225.829,225.571z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </g>
          <g>
            <path
              clipRule="evenodd"
              d="M121.265,255.884c-0.006-1.646,0.014-3.273,0.059-4.887      c1.113-35.124,14.225-65.242,39.335-90.356l36.426,36.422c-16.217,16.235-24.306,35.842-24.265,58.821c0,0.059,0,0.116,0,0.176      c-0.015,2.561,0.083,5.084,0.291,7.563v0.057c1.61,19.688,9.602,36.736,23.974,51.143c0.035,0.037,0.073,0.075,0.117,0.116      l-36.485,36.423l-0.058-0.059c-26.237-26.289-39.368-58.037-39.394-95.243C121.265,256,121.265,255.942,121.265,255.884z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </g>
          <g>
            <path
              clipRule="evenodd"
              d="M35.437,225.28c6.121-48.44,27.186-90.661,63.192-126.662      l36.425,36.423c-29.26,29.239-45.727,63.78-49.402,103.621c-0.51,5.681-0.763,11.479-0.756,17.397      c0.002,47.206,16.684,87.524,50.042,120.959l0.058,0.059l-36.426,36.421l-0.058-0.058      c-43.359-43.459-65.025-95.92-64.997-157.381C33.521,245.532,34.161,235.271,35.437,225.28z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
