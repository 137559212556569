import { REDUCERS } from '../../constants/reducers';

import { AnimalService } from '../../services';

export const setSelectedFilters = value => ({
  type: REDUCERS.ANIMAL.SET_SELECTED_FILTERS,
  value
});

export const fetchAnimalsRequest = () => ({
  type: REDUCERS.ANIMAL.FETCH_ANIMAL_REQUEST
});

export const fetchAnimalsSuccess = data => ({
  type: REDUCERS.ANIMAL.FETCH_ANIMAL_SUCCESS,
  payload: data
});

export const fetchAnimalsFailure = error => ({
  type: REDUCERS.ANIMAL.FETCH_ANIMAL_FAILURE,
  payload: error
});

export const fetchAnimals = () => {
  return async dispatch => {
    dispatch(fetchAnimalsRequest());

    try {
      const { data } = await AnimalService.fetchAnimals();
      const payload = {
        data,
        sireAry: data
          .filter(s => s.sex === 'male')
          .map(x => ({
            value: x.id,
            label: x.identifier + ' (' + x.eartag_management_id + ')'
          })),
        calfAry: data.map(x => ({
          value: x.id,
          label: x.identifier + ' (' + x.eartag_management_id + ')'
        }))
      };
      dispatch(fetchAnimalsSuccess(payload));
    } catch (error) {
      dispatch(fetchAnimalsFailure(error.message));
    }
  };
};
