'use strict';

import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import { FormGroup, Input } from 'reactstrap';

import moment from 'moment';

import axios from '../axios';

import { DATE_FORMAT } from '../constants/common';
import { NOTIFICATION_STATUS } from '../constants/notifications';

import { getNotifications, setNotificationState } from '../redux/actions/notification';

import Priority from '../components/PriorityTag';

import SelectionPopover from './Popovers/SelectionPopover';

const COLUMNS1 = [
  {
    id: 'id',
    Header: 'Notification ID',
    accessor: d => {
      return { id: d.id, identifier: d.identifier };
    },
    headerClassName: 'wordwrap',
    Cell: props => <a href={'/notification/' + props.value.id}>{props.value.identifier}</a>,
    maxWidth: 140,
    minWidth: 140,
    sortMethod: (a, b) => {
      return a.identifier.localeCompare(b.identifier);
    }
  },
  {
    Header: 'Date',
    accessor: 'created_at',
    Cell: props => moment(props.value).format(DATE_FORMAT.DATETIME),
    headerClassName: 'wordwrap',
    maxWidth: 170
  },
  {
    Header: 'Notification',
    accessor: 'message',
    headerClassName: 'wordwrap',
    minWidth: 300
  },
  {
    Header: 'Type',
    accessor: 'type',
    headerClassName: 'wordwrap capitalize',
    className: 'capitalize',
    maxWidth: 140
  },
  {
    Header: 'Count',
    accessor: 'count',
    headerClassName: 'wordwrap',
    maxWidth: 120
  },
  {
    Header: 'Priority',
    id: 'priority',
    accessor: d => {
      return { priority: d.priority, index: d.priority_index };
    },
    headerClassName: 'wordwrap',
    Cell: props => <Priority priority={props.value.priority} isClear={props.original.cleared_at}></Priority>,
    sortMethod: (a, b) => {
      return a.index - b.index;
    },
    maxWidth: 110
  },
  {
    Header: 'Age',
    accessor: 'created_at',
    headerClassName: 'wordwrap',
    Cell: props => moment.duration(moment(props.value).diff(moment(new Date()))).humanize() + ' ago',
    maxWidth: 140
  },
  {
    Header: 'Action Field',
    accessor: 'actions',
    headerClassName: 'wordwrap',
    maxWidth: 130
  }
];

function SimpleAlerts(props) {
  let rows = [];
  for (let d of props.data.slice(0, 10)) {
    rows.push(
      <li className="simple-alerts-item">
        <b>[{moment(d.created_at).format(DATE_FORMAT.DATE)}]</b> {d.message}
      </li>
    );
  }
  if (props.data.length == 0) {
    rows.push(<li>No alerts trigered.</li>);
  }
  return <ul className="simple-alerts">{rows}</ul>;
}

function TableAlerts(props) {
  const dispatch = useDispatch();

  const [isOpenSelectionPopover, setOpenSelectionPopover] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);

  const notificationState = useSelector(state => state.notification);
  const onClickOnThisPage = () => {
    const newCheckboxValue = !selectAll;

    let checkedCopy = [];
    if (newCheckboxValue) {
      notificationState.alerts.slice(page * pageSize, (page + 1) * pageSize).map(val => {
        const id = val?._original?.id ?? val.id;
        checkedCopy.push(id);
      });
    }
    dispatch(setNotificationState({ selectedAlerts: checkedCopy }));
    setSelectAll(newCheckboxValue);
    onToggleSelectionPopover();
  };
  const onClickAllRecords = () => {
    const newCheckboxValue = !selectAll;
    let checkedCopy = [];
    if (newCheckboxValue) {
      notificationState.alerts.map(val => {
        checkedCopy.push(val.id);
      });
    }
    dispatch(setNotificationState({ selectedAlerts: checkedCopy }));
    setSelectAll(newCheckboxValue);

    onToggleSelectionPopover();
    if (notificationState.alertsCount > 100) {
      errorToastHandler(messages.NOTIFICATIONS_LIMIT);
    }
  };
  const onNotSelect = id => {
    let currentSelectedRecords = [...notificationState.selectedAlerts];
    if (currentSelectedRecords.includes(id)) {
      var index = currentSelectedRecords.indexOf(id);
      if (index > -1) {
        currentSelectedRecords.splice(index, 1);
      }
    } else {
      currentSelectedRecords.push(id);
    }
    const isSelectedAll = notificationState.alerts?.length === currentSelectedRecords.length;

    setSelectAll(isSelectedAll);
    dispatch(setNotificationState({ selectedAlerts: currentSelectedRecords }));
  };
  const onToggleSelectionPopover = () => {
    const open = isOpenSelectionPopover ? false : !isOpenSelectionPopover && !selectAll;
    setOpenSelectionPopover(open);
  };
  const COLUMNS2 = [
    {
      Header: () => (
        <div className="position-relative form-check">
          <SelectionPopover
            isOpen={isOpenSelectionPopover}
            onClickOnThisPage={onClickOnThisPage}
            onClickAllRecords={onClickAllRecords}
            setOpen={() => setOpenSelectionPopover(prevState => !prevState)}
            content={
              <input
                type="checkbox"
                defaultChecked={selectAll}
                onChange={() => {
                  onToggleSelectionPopover();
                  if (selectAll) {
                    setSelectAll(false);
                    dispatch(setNotificationState({ selectedAlerts: [] }));
                  }
                }}
              />
            }
          />
        </div>
      ),
      id: 'select',
      accessor: d => {
        return { id: d.id, tag: d.tag };
      },
      Cell: props => (
        <FormGroup check>
          <Input
            type="checkbox"
            defaultChecked={notificationState.selectedAlerts.includes(props.value.id)}
            value={props.value.id}
            onChange={e => onNotSelect(parseInt(e.target.value))}
            key={props.value.id}
          />{' '}
        </FormGroup>
      ),
      headerClassName: 'wordwrap',
      sortable: false,
      filterable: false,
      maxWidth: 85
    },
    {
      Header: 'Notification ID',
      id: 'id',
      accessor: d => {
        return { id: d.id, identifier: d.identifier };
      },
      headerClassName: 'wordwrap',
      sortMethod: (a, b) => {
        return a.identifier.localeCompare(b.identifier);
      },
      Cell: props => (
        <a href="#" onClick={() => this.props.history.push('notification/' + props.value.id)}>
          {props.value.identifier}
        </a>
      ),
      minWidth: 130,
      maxWidth: 130
    },
    {
      Header: 'Notification Priority',
      id: 'priority',
      accessor: d => {
        return {
          priority: d.priority,
          identifier: d.priority,
          index: d.priority_index
        };
      },
      headerClassName: 'wordwrap',
      Cell: props => <Priority priority={props.value.priority} isClear={props.row.actions.clear}></Priority>,
      sortMethod: (a, b) => {
        return a.index - b.index;
      },
      maxWidth: 130
    },
    {
      Header: 'Notification Type',
      accessor: 'type',
      headerClassName: 'wordwrap capitalize',
      className: 'capitalize',
      maxWidth: 130
    },
    {
      Header: 'Notification Rule',
      accessor: 'rule.name',
      headerClassName: 'wordwrap',
      minWidth: 300
    },
    {
      Header: 'Livestock Count',
      accessor: 'animals',
      headerClassName: 'wordwrap',
      Cell: props => {
        return <div>{props.original.animals?.length ?? 0}</div>;
      },
      minWidth: 100
    },
    {
      Header: 'Notification Trigger Date',
      id: 'notiifcationDate',
      accessor: d => {
        return moment(d.created_at).format(DATE_FORMAT.DATETIME);
      },
      headerClassName: 'wordwrap',
      sortable: true,
      sortMethod: (a, b) => {
        return moment(b).format('x') - moment(a).format('x');
      },
      maxWidth: 180
    },
    {
      Header: 'Snooze / Clear',
      id: 'actions',
      accessor: d => {
        return { snooze: d.status, clear: d.cleared_at != null, id: d.id };
      },
      headerClassName: 'wordwrap',
      sortable: false,
      filterable: false,
      Cell: cellProps => (
        <div>
          <i
            onClick={() => props?.onSnoozeClick(cellProps.value.id)}
            className={'not-action mdi mdi-alarm ' + cellProps.value.snooze}></i>
          <i
            onClick={() => props?.onClearClick(cellProps.value.id)}
            className={'not-action clear mdi mdi-check-circle-outline ' + cellProps.value.clear}></i>
        </div>
      ),
      maxWidth: 100
    },
    {
      Header: 'Notification Status',
      id: 'notificationStat',
      headerClassName: 'wordwrap',
      accessor: d => {
        return NOTIFICATION_STATUS[d.status];
      },
      maxWidth: 120
    }
  ];
  const columns = props?.props?.columnType === 2 ? COLUMNS2 : COLUMNS1;
  return (
    <div className="allow-overflow">
      <ReactTable
        columns={columns}
        data={notificationState.alerts}
        defaultPageSize={25}
        showPagination={notificationState.alerts?.length > 0}
        minRows={0}
        onFetchData={props => {
          setPageSize(props.pageSize);
          setPage(props.page);
        }}
        getTrProps={(state, rowInfo, instance) => {
          if (rowInfo) {
            return {
              style: {
                background: rowInfo.original.cleared_at !== null ? '#f0f0f0' : '#ffffff'
              }
            };
          }
          return {};
        }}></ReactTable>
    </div>
  );
}

class Alerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      props: this.props
    };
    this.getData = this.getData.bind(this);
    this.snoozeNotification = this.snoozeNotification.bind(this);
    this.clearNotification = this.clearNotification.bind(this);
  }
  async componentDidMount() {
    this.getData();
  }
  async getData() {
    const data = this.props.getNotifications({
      ...this.state.props.query
    });
    if (this.props.filter && typeof this.props.filter.filter !== 'undefined') {
      this.setState(state => ({
        data: data.notifications?.filter(i => this.props.filter.filter(i))
      }));
    } else {
      this.setState(state => ({
        data: data.notifications
      }));
    }
  }
  async snoozeNotification(id) {
    let response = await axios.put('notifications/' + id + '/snooze');
    if (response.status == 200) {
      this.getData();
    }
  }

  async clearNotification(id) {
    let response = await axios.put('notifications/' + id + '/clear');
    if (response.status == 200) {
      this.getData();
    }
  }
  render() {
    if (this.state.props.type === 'simple') {
      return <SimpleAlerts data={this.state.data} props={this.state.props}></SimpleAlerts>;
    } else {
      return (
        <TableAlerts
          data={this.state.data}
          props={this.state.props}
          onClearClick={this.clearNotification}
          onSnoozeClick={this.snoozeNotification}></TableAlerts>
      );
    }
  }
}

export default connect(
  state => ({
    alerts: state?.notification?.alerts
  }),
  dispatch => ({
    getNotifications: params => {
      return dispatch(getNotifications(params));
    }
  })
)(Alerts);
