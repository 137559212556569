import { REDUCERS } from '../../constants/reducers';

const initialState = {
  data: null,
  loading: false,
  error: null,
  selectedFilters: {}
};

const measurement = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.MEASUREMENT.SET_SELECTED_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        selectedFilters: action.value
      });
    }
    case REDUCERS.MEASUREMENT.DELETE_MEASUREMENTS:
      return {
        ...state,
        data: state.data.filter(record => !action.payload.includes(record.id))
      };
    case REDUCERS.MEASUREMENT.UPDATE_MEASUREMENT_FAILURE:
      return { ...state, updating: false, error: action.payload };
    case REDUCERS.MEASUREMENT.FETCH_MEASUREMENTS_REQUEST:
      return { ...state, loading: true, data: null, error: null };
    case REDUCERS.MEASUREMENT.FETCH_MEASUREMENTS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case REDUCERS.MEASUREMENT.FETCH_MEASUREMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export { measurement };
