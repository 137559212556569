import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';

import { Button, Select } from 'antd';
import debounce from 'lodash.debounce';
import moment from 'moment/moment';

import axios from '../../../axios';

import { capitalize } from '../../../helpers/common';
import { getFilterOptions, sortDates, sortNumbers, sortStrings } from '../../../helpers/filter';

import { DATE_FORMAT, EDIT_COMPONENT_TYPE, KEY_PRESS_CODE, SEARCH_DEBOUNCE_DELAY } from '../../../constants/common';
import { BLANK_FILTER_TEXT } from '../../../constants/livestock';
import messages from '../../../constants/messages';

import { setSelectedFilters } from '../../../redux/actions/animal';

import AppModal from '../../../components/AppModal';
import AppTable from '../../../components/AppTable';
import { COLUMN_SIZE } from '../../../components/AppTable/constants';
import { errorToastHandler, successToastHandler } from '../../../components/action_notifier';
import Livestock from '../../../components/animals/Livestock';
import StatusIndicator from '../../../components/statusIndicator';

import { SocketContext } from '../../../context/socket';
import { TagService } from '../../../services';

function TagsTable() {
  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();
  const { selectedFilters } = useSelector(state => state.tag);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [state, setState] = useState({
    actions: [],
    add_modal: false,
    animals: [],
    columnFilterOptions: {
      batteryStatuses: [],
      connectionStatuses: [],
      farms: [],
      linkedStatuses: [],
      locations: []
    },
    csv_modal: false,
    csvfile: '',
    delete_modal: false,
    editableIds: [],
    farm_opts: [],
    filters: {
      farms: [],
      geofences: [],
      labels: []
    },
    filters_open: false,
    isOpenSelectionPopover: false,
    isTagsLoading: true,
    isAnimalsLoading: true,
    link_modal: false,
    new_tag: {},
    query: '',
    search_livestock: '',
    selectAll: false,
    selected_animals: [],
    selected_farm: {},
    selected_tags: [],
    selected_tags_unlinked: [],
    tableData: [],
    tags: [],
    uploading: false,
    ws: null
  });

  useEffect(() => {
    getTags();
    getAnimals();
    getFarms();
    initActions();
  }, []);

  useEffect(() => {
    initActions();
  }, [state.selected_tags, selectedFilters]);

  useEffect(() => {
    if (socket && !state.ws) {
      setState(prevState => ({ ...prevState, ws: socket }));

      socket.on('tagStatus', data => {
        setState(prevState => ({
          ...prevState,
          tags: state.tags?.map(item => {
            let status = item.status;

            if (data.ids.includes(item.id)) {
              status = data.status;
            }

            return { ...item, status };
          })
        }));
      });
    }

    return () => socket.off('tagStatus');
  }, [socket]);

  const initActions = () => {
    const { selected_tags } = state;

    setState(prevState => ({
      ...prevState,
      actions: [
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => toggleModal('add_modal')}>
              Add Kraal Tag
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!selected_tags?.length}
              style={{ padding: 0 }}
              onClick={() => setDeleteTags()}>
              Archive Kraal Tag
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => toggleModal('csv_modal')}>
              Upload Kraal Tag list
            </Button>
          )
        },
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              disabled={!Object.values(selectedFilters)?.length}
              style={{ padding: 0 }}
              onClick={() => clearFilters()}>
              Clear all filters
            </Button>
          )
        }
      ]
    }));
  };

  const clearFilters = () => {
    setFilteredInfo({});
    dispatch(setSelectedFilters({}));
  };

  const setFilters = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  const handleCSVChange = event => {
    setState(prevState => ({ ...prevState, uploading: true }));

    const reader = new FileReader();
    reader.readAsText(event.target.files[0]);

    reader.onload = function () {
      setState(prevState => ({ ...prevState, csvfile: reader.result, uploading: false }));
    }.bind(this);

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };

  const debounceSearch = useCallback(
    debounce(cb => {
      cb();
    }, SEARCH_DEBOUNCE_DELAY),
    []
  );

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleKeyPress = charCode => {
    if (charCode === KEY_PRESS_CODE) {
      setState(prevState => ({ ...prevState, isTagsLoading: true }));
      debounceSearch(getTags);
    }
  };

  const onNewTagChange = (value, field) => {
    const nt = state.new_tag;
    nt[field] = value;

    setState(prevState => ({ ...prevState, newTag: nt }));
  };

  const getTags = async () => {
    const response = await axios.get('tags', {
      params: {
        query: state.query,
        farm_ids: state.filters.farms?.map(x => x.value),
        geofence_ids: state.filters.geofences?.map(x => x.value),
        site_ids: state.filters.sites?.map(x => x.value),
        label_ids: state.filters.labels?.map(x => x.value)
      }
    });

    if (response.status === 200) {
      setState(prevState => ({
        ...prevState,
        tags: response.data,
        tableData: response.data.slice(0, 25),
        isTagsLoading: false,
        columnFilterOptions: {
          ...prevState.columnFilterOptions,
          batteryStatuses: getFilterOptions(response?.data, 'battery_status_label'),
          connectionStatuses: getFilterOptions(response?.data, 'status'),
          linkedStatuses: [...new Set(response.data?.map(d => (d.animal ? 'Linked' : 'Not Linked')))].map(geofence => ({
            text: geofence,
            value: geofence
          })),
          locations: [
            ...new Set(response.data?.map(d => (d.animal ? d.animal?.geofences?.at(0)?.name : BLANK_FILTER_TEXT)))
          ].map(geofence => ({ text: geofence, value: geofence }))
        }
      }));

      await getAnimals();
    }
  };

  const linkTag = async () => {
    const animalId = state.selected_animals[0];
    const animal = state.animals.filter(a => a.id === animalId)[0];
    let response;

    if (state.re_linking) {
      response = await axios.put('links', {
        tag_id: state.selected_tags_unlinked?.at(0),
        animal_id: animal.id
      });
    } else {
      response = await axios.post('links', {
        tag_id: state.selected_tags_unlinked?.at(0),
        animal_id: animal.id
      });
    }

    if (response.status === 200) {
      getTags();
      toggleModal('link_modal');
      setState(prevState => ({ ...prevState, re_linking: false, linking: false }));

      window.location.reload();
    }
  };

  const addTag = async () => {
    await axios.post('tags', { ...state.new_tag });

    toggleModal('add_modal');

    await getTags();
  };

  const toggleModal = modal => {
    setState(state => ({ ...state, [modal]: !state[modal] }));
  };

  const getAnimals = async () => {
    const response = await axios.get('animals?query=' + state.search_livestock);

    if (response.status === 200)
      setState(prevState => ({
        ...prevState,
        animals: response.data,
        isAnimalsLoading: false,
        columnFilterOptions: {
          ...prevState.columnFilterOptions,
          farms: getFilterOptions(response?.data, 'farm', 'name')
        }
      }));
  };

  const setDeleteTags = () => {
    setState(prevState => ({ ...prevState, delete_tags: state.selected_tags }));
    toggleModal('delete_modal');
  };

  const deleteTag = async () => {
    const response = await axios.delete('tags/bulk', {
      data: {
        ids: state.delete_tags
      }
    });

    if (response.status === 200) {
      await getTags();
      toggleModal('delete_modal');
    }
  };

  const getLabel = async id => {
    const tags = state.tags?.filter(t => t.id === id);

    return tags?.length > 0 ? tags?.at(0).diagri_id : '';
  };

  const handleCSVFarmChange = selectedOption => {
    setState(prevState => ({ ...prevState, csv_farm: selectedOption }));
  };

  const handleFarmChange = selectedOption => {
    setState(prevState => ({
      ...prevState,
      selected_farm: selectedOption,
      new_tag: { ...prevState.new_tag, farm_id: selectedOption.value }
    }));
  };

  const uploadCSV = async () => {
    const csv = state.csvfile;
    const response = await axios.post('tags/csv', {
      csv: csv,
      farm_id: state.csv_farm.value
    });

    if (response.status === 200) {
      await getTags();
      toggleModal('csv_modal');
    }
  };

  const getFarms = async () => {
    const response = await axios.get('farms/', {
      query: state.query || null
    });

    if (response.status === 200) {
      const mapped_farms = [];

      for (let farm of response.data) {
        mapped_farms.push({ value: farm.id, label: farm.name });
      }

      setState(prevState => ({
        ...prevState,
        farms: response.data,
        farm_opts: mapped_farms
      }));
    }
  };

  const onClickSaveEdit = async data => {
    const payload = { farm_id: data?.at(0).farm?.value };

    try {
      await TagService.updateOne(data?.at(0).id, payload);
      setState(prevState => ({ ...prevState, editableIds: [], selected_tags: [] }));
      await getTags();
      successToastHandler(messages.UPDATED);
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.DEFAULT_ERROR);
    }
  };

  const selectRecords = selected => {
    setState(prevState => ({
      ...prevState,
      selected_tags: selected
    }));
  };

  const commonColumnProperties = {
    ellipsis: true,
    sortDirections: ['ascend', 'descend']
  };

  const columns = [
    {
      ...commonColumnProperties,
      title: 'Kraal Tag ID',
      dataIndex: 'diagri_id',
      filteredValue: filteredInfo.diagri_id || null,
      fixed: 'left',
      searchable: true,
      width: COLUMN_SIZE.XL,
      render: (value, record) => <Link to={`/tag/${record.id}`}>{record.diagri_id || '/'}</Link>,
      sorter: (a, b) => sortStrings(a.diagri_id, b.diagri_id)
    },
    {
      ...commonColumnProperties,
      title: 'Livestock ID',
      dataIndex: 'livestockId',
      filteredValue: filteredInfo.livestockId || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => {
        const searchField = record?.animal?.identifier || 'Unlinked';

        return searchField.toString().toLowerCase().includes(value.toLowerCase());
      },
      render: (value, record) =>
        record?.animal ? <Link to={`/animal/${record?.animal?.id}`}>{record?.animal?.identifier}</Link> : 'Unlinked',
      sorter: (a, b) => sortStrings(a.animal?.identifier, b.animal?.identifier)
    },
    {
      ...commonColumnProperties,
      title: 'Farm',
      dataIndex: 'farm',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: state.farm_opts,
        valueKey: 'id'
      },
      filterSearch: true,
      filteredValue: filteredInfo.farm || null,
      filters: state.columnFilterOptions.farms,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.farm?.name : record?.farm?.name === value),
      sorter: (a, b) => sortStrings(a.farm?.name, b.farm?.name),
      render: (value, record) => value?.name ?? ''
    },
    {
      ...commonColumnProperties,
      title: 'Location',
      dataIndex: 'animal',
      filteredValue: filteredInfo.animal || null,
      filters: state.columnFilterOptions.locations,
      width: COLUMN_SIZE.XXL,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT
          ? !record?.animal?.geofences?.length
          : record?.animal?.geofences?.at(0)?.name === value,
      render: (value, record) => (
        <Link to={`tracking/${record?.animal?.id}`}>{record?.animal?.geofences?.at(0)['name']}</Link>
      ),
      sorter: (a, b) => sortStrings(a.animal?.geofences?.at(0)['name'], b.animal?.geofences?.at(0)['name'])
    },
    {
      ...commonColumnProperties,
      title: 'Battery Status',
      dataIndex: 'battery_status',
      filteredValue: filteredInfo.battery_status || null,
      filters: state.columnFilterOptions.batteryStatuses,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => record?.battery_status_label.toLowerCase() === value.toLowerCase(),
      sorter: (a, b) => sortNumbers(a?.battery_percentage, b?.battery_percentage),
      render: (value, record) => (
        <div className="d-flex align-items-center">
          <StatusIndicator key={record?.id} status={record?.battery_status} />
          {capitalize(record.battery_status_label)}
        </div>
      )
    },
    {
      ...commonColumnProperties,
      title: 'Connection Status',
      dataIndex: 'status',
      filteredValue: filteredInfo.status || null,
      filters: state.columnFilterOptions.connectionStatuses,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) => record?.status === value,
      sorter: (a, b) => sortStrings(a?.status, b?.status),
      render: (value, record) => {
        return (
          record && (
            <div className="d-flex align-items-center">
              <StatusIndicator key={record?.id} status={record?.status} />
              {capitalize(record?.status)}
            </div>
          )
        );
      }
    },
    {
      ...commonColumnProperties,
      title: 'Last Reading',
      dataIndex: 'timestamp_at',
      filteredValue: filteredInfo.timestamp_at || null,
      searchable: true,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) => record?.timestamp_at?.includes(value.toLowerCase()),
      sorter: (a, b) => sortDates(a?.timestamp_at, b?.timestamp_at),
      render: (value, record) => {
        const lastReadingTime = record?.timestamp_at;

        return lastReadingTime ? moment(lastReadingTime).format(DATE_FORMAT.DATETIME) : null;
      }
    },
    {
      ...commonColumnProperties,
      title: 'Linked Status',
      dataIndex: 'animal_status',
      filteredValue: filteredInfo.animal_status || null,
      filters: state.columnFilterOptions.linkedStatuses,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => (value === 'Linked' ? record?.animal : !record?.animal),
      sorter: (a, b) => {
        const strA = a.animal ? 'Linked' : 'Not Linked';
        const strB = b.animal ? 'Linked' : 'Not Linked';

        return sortStrings(strA, strB);
      },
      render: (value, record) => (record.animal ? 'Linked' : 'Not Linked')
    }
  ];

  return (
    <>
      <AppTable
        headerClass="py-2"
        actions={state.actions}
        baseColumns={columns}
        dataSource={state.tags}
        filterable={false}
        loading={state.isAnimalsLoading || state.isTagsLoading}
        searchDefaultValue={state.query}
        searchPlaceholder="Search Kraal tags"
        handleOnChange={setFilters}
        handleOnSelectRecords={selectRecords}
        handleSearchChange={e => onChange(e.target.value, 'query')}
        handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
        updateTableData={onClickSaveEdit}
      />

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonColor="danger"
        confirmButtonText="Archive"
        title="Archive tags"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={deleteTag}>
        <div className="py-4">Are you sure you want to archive selected tags? This action cannot be undone.</div>
      </AppModal>

      <AppModal
        isOpen={state.add_modal}
        confirmButtonText="Add tag"
        title="Add Kraal Tag"
        handleCancel={() => toggleModal('add_modal')}
        handleConfirm={addTag}>
        <Form className="py-4">
          <FormGroup row>
            <Label sm="3">Tag DEVEUI</Label>
            <Col sm="9">
              <Input type="text" placeholder="DEVEUI" onChange={e => onNewTagChange(e.target.value, 'deveui')} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Kraal Tag ID</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="Kraal Tag ID"
                onChange={e => onNewTagChange(e.target.value, 'diagri_id')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag Version</Label>
            <Col sm="9">
              <Input type="text" placeholder="Tag Version" onChange={e => onNewTagChange(e.target.value, 'version')} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag Firmware Version</Label>
            <Col sm="9">
              <Input
                type="text"
                placeholder="Firmware Version"
                onChange={e => onNewTagChange(e.target.value, 'firmware_version')}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm="3">Tag farm</Label>
            <Col sm="9">
              <Select
                placeholder="Select a Farm"
                options={state.farm_opts}
                style={{ width: '100%' }}
                value={state.selected_farm}
                onChange={handleFarmChange}
              />
            </Col>
          </FormGroup>
        </Form>
      </AppModal>

      <AppModal
        isOpen={state.link_modal}
        confirmButtonText="Link"
        title="Linking confirmation"
        handleCancel={() => toggleModal('link_modal')}
        handleConfirm={linkTag}>
        <p className="text-center">
          Tag <b>{getLabel(state.selected_tags_unlinked?.at(0))}</b> will be linked to
        </p>
        <Livestock animals={state.animals} animal={state.selected_animals}></Livestock>
      </AppModal>

      <AppModal
        isOpen={state.csv_modal}
        confirmButtonText="Upload"
        title="CSV file upload"
        handleCancel={() => toggleModal('csv_modal')}
        handleConfirm={uploadCSV}>
        <div className="py-4">
          <div className="mb-4">
            Download{' '}
            <a href="/files/tags_import_example.csv" style={{ fontWeight: 'bold' }}>
              example
            </a>{' '}
            CSV file.
          </div>

          <FormGroup>
            <Label>Select farm</Label>
            <Select
              options={state.farm_opts}
              style={{ width: '100%' }}
              value={state.csv_farm}
              onChange={handleCSVFarmChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>CSV file</Label>
            <Input type="file" onChange={handleCSVChange}></Input>
          </FormGroup>
        </div>
      </AppModal>
    </>
  );
}

export default memo(TagsTable);
