import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import { admin } from './reducers/admin';
import { animal } from './reducers/animal';
import { breed } from './reducers/breed';
import { breeding } from './reducers/breeding';
import { calendar } from './reducers/calendar';
import { event } from './reducers/event';
import { farm } from './reducers/farm';
import { gateway } from './reducers/gateway';
import { group } from './reducers/group';
import { location } from './reducers/location';
import { matingEvent } from './reducers/mating-event';
import { measurement } from './reducers/measurement';
import { measurementType } from './reducers/measurement-type';
import { notification } from './reducers/notification';
import { rule } from './reducers/rule';
import { subscription } from './reducers/subscription';
import { tag } from './reducers/tag';
import { task } from './reducers/task';
import treatmentReducer from './reducers/treatment';
import { user } from './reducers/user';

const rootReducer = {
  admin,
  animal,
  breed,
  breeding,
  calendar,
  event,
  farm,
  gateway,
  group,
  location,
  matingEvent,
  measurement,
  measurementType,
  notification,
  rule,
  subscription,
  tag,
  task,
  treatment: treatmentReducer,
  user
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk)
});

export default store;
