import { formatArrayToFilterOptions, formatArrayToOptions } from '../../helpers/common';

import { REDUCERS } from '../../constants/reducers';

import { BreedService } from '../../services';

export const fetchBreedsRequest = () => ({
  type: REDUCERS.BREEDS.FETCH_BREEDS_REQUEST
});

export const fetchBreedsSuccess = data => ({
  type: REDUCERS.BREEDS.FETCH_BREEDS_SUCCESS,
  payload: data
});

export const fetchBreedsFailure = error => ({
  type: REDUCERS.BREEDS.FETCH_BREEDS_FAILURE,
  payload: error
});

export const fetchBreeds = () => {
  return async (dispatch, getState) => {
    const { breeds } = getState().breed;

    if (breeds) return;

    dispatch(fetchBreedsRequest());

    try {
      const { data } = await BreedService.fetchBreeds();
      const payload = {
        breeds: data,
        breedsSelectOptions: formatArrayToOptions(data, 'display_name', 'id'),
        breedsFilterOptions: formatArrayToFilterOptions(data, 'display_name', 'id')
      };

      dispatch(fetchBreedsSuccess(payload));
    } catch (error) {
      dispatch(fetchBreedsFailure(error.message));
    }
  };
};
