import axios from '../../axios';

const service = {
  createBreedings(data) {
    return axios.request({ method: 'post', url: 'breeding/store/bulk', data: { breeding_events: data } });
  },
  fetchBreeding(params) {
    return axios({ method: 'get', url: '/breeding/index', params });
  },
  fetchBreedingEventTypes() {
    return axios({ method: 'get', url: '/breeding_event_types' });
  },
  fetchBreedingEventStatus() {
    return axios({ method: 'get', url: '/breeding_event_status' });
  },
  deleteBreedings(ids) {
    return axios({ method: 'delete', url: 'breeding/destroy_bulk', data: { ids } });
  }
};

export default service;
