import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { capitalize } from '../../helpers/common';
import { getFilterOptions, sortDates, sortNumbers, sortStrings } from '../../helpers/filter';

import { DATE_FORMAT, KEY_PRESS_CODE } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import { RULE_PRIORITY_INDEX } from '../../constants/rule';
import { TASK_MODAL_MODE } from '../../constants/task';

import { getAllTasks, setCurrentTask } from '../../redux/actions/task';
import { setSelectedFilters } from '../../redux/actions/task';

import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';

import TaskModal from '../Modals/TaskModal';
import './styles.scss';

const Tasks = ({
  actions,
  queryParameters = {},
  rowsSelectable = false,
  searchPlaceholder = '',
  searchable = false,
  settings = false,
  tableMaxHeight,
  title
}) => {
  const dispatch = useDispatch();
  const { tasks, selectedFilters, loading } = useSelector(state => state.task);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [isOpenTaskModal, setOpenTaskModal] = useState(false);
  const [priorityFilterOptions, setPriorityFilterOptions] = useState([]);
  const [query, setSearchQuery] = useState('');
  const [statusFilterOptions, setStatusFilterOptions] = useState([]);

  useEffect(() => {
    dispatch(getAllTasks(queryParameters));
  }, []);

  useEffect(() => {
    const priorityFilterOptions = getFilterOptions(tasks, 'priority');
    const statusFilterOptions = getFilterOptions(tasks, 'status');

    setPriorityFilterOptions(priorityFilterOptions);
    setStatusFilterOptions(statusFilterOptions);
  }, [tasks]);

  useEffect(() => {
    setFilteredInfo(selectedFilters);
  }, [selectedFilters]);

  const setFilters = (pagination, filters) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  const onSubmit = () => {
    setOpenTaskModal(false);
  };

  const onTaskClick = task => {
    dispatch(setCurrentTask(task));
    setOpenTaskModal(true);
  };

  function onChange(value) {
    setSearchQuery(prevState => ({ ...prevState, query: value }));
  }

  async function handleKeyPress(charCode) {
    const params = {
      ...queryParameters,
      ...query
    };

    if (charCode === 13) dispatch(getAllTasks(params));
  }

  const commonColumnProperties = {
    ellipsis: true,
    searchable: true,
    sortDirections: ['ascend', 'descend']
  };

  const columns = [
    {
      ...commonColumnProperties,
      title: 'Title',
      dataIndex: 'title',
      filteredValue: filteredInfo.title || null,
      width: COLUMN_SIZE.XL,
      render: (value, record) => (
        <span className="title-column" onClick={() => onTaskClick(record)}>
          {record?.title}
        </span>
      ),
      sorter: (a, b) => sortStrings(a.title, b.title)
    },
    {
      ...commonColumnProperties,
      title: 'Due date',
      dataIndex: 'due_date',
      filteredValue: filteredInfo.due_date || null,
      width: COLUMN_SIZE.LG,
      render: (value, record) => moment(record.due_date).format(DATE_FORMAT.DATETIME),
      sorter: (a, b) => sortDates(a.due_date, b.due_date)
    },
    {
      ...commonColumnProperties,
      title: 'Priority',
      dataIndex: 'priority',
      filteredValue: filteredInfo.priority || null,
      filters: priorityFilterOptions,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.priority : record?.priority === value),
      render: (value, record) => capitalize(record?.priority),
      sorter: (a, b) => sortNumbers(RULE_PRIORITY_INDEX[a.priority], RULE_PRIORITY_INDEX[b.priority])
    },
    {
      ...commonColumnProperties,
      title: 'Status',
      dataIndex: 'status',
      filteredValue: filteredInfo.status || null,
      filters: statusFilterOptions,
      width: COLUMN_SIZE.MD,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.status : record?.status === value),
      render: (value, record) => capitalize(record.status),
      sorter: (a, b) => sortStrings(a.status, b.status)
    }
  ];

  return (
    <>
      <div className="mx-2">
        <AppTable
          actions={actions}
          baseColumns={columns}
          dataSource={tasks}
          headerClass="py-2"
          loading={loading}
          pageSize={queryParameters?.limit}
          rowsSelectable={rowsSelectable}
          searchPlaceholder={searchPlaceholder}
          searchable={searchable}
          settings={settings}
          tableMaxHeight={tableMaxHeight}
          title={title}
          handleOnChange={setFilters}
          handleSearchChange={e => onChange(e.target.value)}
          handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
        />
      </div>

      <TaskModal
        isOpen={isOpenTaskModal}
        onCancel={() => setOpenTaskModal(false)}
        onSubmit={onSubmit}
        mode={TASK_MODAL_MODE.VIEW}
      />
    </>
  );
};

export default memo(Tasks);
