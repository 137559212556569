import React, { memo, useRef, useState } from 'react';

import AppModal from '../../components/AppModal';

import CreateTreatmentForm from './CreateTreatmentForm';

function CreateTreatmentModal({ data, isOpen, handleCancel, handleConfirm }) {
  const formRef = useRef(null);
  const [submittable, setSubmittable] = useState(false);

  const onConfirm = async () => {
    try {
      const values = await formRef.current?.validateFields();

      handleConfirm(values);
      formRef?.current?.resetFields();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const onCancel = () => {
    handleCancel();
    formRef.current?.resetFields();
  };

  return (
    <AppModal
      isOpen={isOpen}
      confirmButtonText="Create Treatments"
      confirmButtonDisabled={!submittable}
      title="Create New Treatments"
      handleCancel={onCancel}
      handleConfirm={onConfirm}>
      <CreateTreatmentForm className="py-4" data={data} formRef={formRef} setSubmittable={setSubmittable} />
    </AppModal>
  );
}

export default memo(CreateTreatmentModal);
