import { memo, useEffect, useState } from 'react';

import { Checkbox, DatePicker, Form, Input, InputNumber, Select, Tag } from 'antd';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '../../constants/common';

const customizeRequiredMark = (label, { required }) => (
  <>
    {required ? <Tag color="error">required</Tag> : <Tag color="warning">optional</Tag>}
    {label}
  </>
);

function CreateTreatmentForm(props) {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState('optional');
  const [treatmentDate, setTreatmentDate] = useState('');

  if (props.formRef) props.formRef.current = form;

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => props.setSubmittable(true))
      .catch(() => props.setSubmittable(false));
  }, [values]);

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const handleCheckboxChange = event => {
    form.setFieldsValue({ is_positive: event.target.checked ? 1 : 0 });
  };

  const onChange = (date, dateString) => {
    setTreatmentDate(date);
  };

  return (
    <div className="px-2" style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <Form
        autoComplete="off"
        layout="vertical"
        variant="outlined"
        className={props.className}
        form={form}
        initialValues={{ requiredMarkValue: requiredMark }}
        onValuesChange={onRequiredTypeChange}
        requiredMark={customizeRequiredMark}>
        <Form.Item
          label="Treatment Type"
          name="treatment_type_id"
          rules={[
            {
              required: true,
              message: 'Please select!'
            }
          ]}>
          <Select placeholder="Select Treatment Type" options={props?.data?.treatmentTypesSelectOptions} />
        </Form.Item>

        <Form.Item
          label="Treatment Date"
          name="treatment_date"
          rules={[{ required: true, message: 'Please enter Treatment date!' }]}>
          <DatePicker format={DATE_FORMAT.DATE} style={{ width: '100%' }} onChange={onChange} />
        </Form.Item>

        <Form.Item label="Symptoms" name="symptoms">
          <Input />
        </Form.Item>

        <Form.Item label="Is Positive" name="is_positive" valuePropName="checked">
          <Checkbox onChange={handleCheckboxChange} />
        </Form.Item>

        <Form.Item label="Treatment" name="treatment" rules={[{ required: true, message: 'Please enter Treatment!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Medication" name="medication">
          <Input />
        </Form.Item>

        <Form.Item label="Dosage UoM" name="dosage_uom">
          <Select placeholder="Select Dosage UoM" options={props?.data?.treatmentUOMSelectOptions} />
        </Form.Item>

        <Form.Item label="Dosage Amount" name="dosage_amount" validateDebounce={1000}>
          <InputNumber placeholder="0" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="Vet" name="vet">
          <Input />
        </Form.Item>

        <Form.Item label="Comments" name="comments">
          <Input />
        </Form.Item>

        <Form.Item
          label="Cost"
          name="cost"
          validateDebounce={1000}
          rules={[
            {
              type: 'number',
              message: 'Please input number value!'
            }
          ]}>
          <InputNumber placeholder="0" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="Repeat Date" name="repeat_date">
          <DatePicker
            disabled={!treatmentDate}
            format={DATE_FORMAT.DATE}
            minDate={dayjs(treatmentDate, DATE_FORMAT.ISO_8601)}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item label="Review Date" name="review_date">
          <DatePicker
            disabled={!treatmentDate}
            format={DATE_FORMAT.DATE}
            minDate={dayjs(treatmentDate, DATE_FORMAT.ISO_8601)}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item label="Result" name="result">
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
}

export default memo(CreateTreatmentForm);
