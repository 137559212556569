import { memo, useEffect, useState } from 'react';

import { Select } from 'antd';

function AppTableSelect({ col, value, record, handleChange }) {
  if (value && typeof value == 'object') {
    value = value.name;
  }

  const defaultObj = col.editConfig.options.find(option => option.value === value || option.label === value);
  const defaultVal = defaultObj ? defaultObj.value : '';
  const [selectedValue, setSelectedValue] = useState(defaultVal);

  useEffect(() => {
    setSelectedValue(defaultVal);
  }, [value, col.editConfig.options]);

  const handleOnChange = (_, newValue) => {
    setSelectedValue(newValue);
    handleChange({
      ...record,
      [col.dataIndex]: newValue
    });
  };

  return (
    <Select
      size="small"
      variant="borderless"
      options={col.editConfig.options}
      style={{ height: '21px', width: '100%' }}
      value={selectedValue}
      onChange={handleOnChange}
    />
  );
}

export default memo(AppTableSelect);
