import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { incrementNextCountCalendar, incrementPrevCountCalendar } from '../../../../redux/actions/calendar';

import PrevNextButtons from '../../../../components/PrevNextButtons';

function CalendarHeaderOptions() {
  const dispatch = useDispatch();

  return (
    <div className="calendar__header-options">
      <PrevNextButtons
        nextId={true}
        prevId={true}
        handlePrev={() => dispatch(incrementPrevCountCalendar())}
        handleNext={() => dispatch(incrementNextCountCalendar())}
      />
    </div>
  );
}

export default memo(CalendarHeaderOptions);
