import { REDUCERS } from '../../constants/reducers';

const initialState = {
  currentTask: {
    description: '',
    endDate: '',
    recurringEndDate: '',
    recurringType: '',
    relationId: '',
    relationType: '',
    remindDate: '',
    startDate: '',
    title: '',
    userEmails: [],
    userIds: []
  },
  queryParams: {},
  selectedFilters: {},
  selectedTasks: [],
  tasks: [],
  loading: false,
  error: null
};

const task = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.TASK.SET_SELECTED_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        selectedFilters: action.value
      });
    }
    case REDUCERS.TASK.SET_TASK_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.TASK.SET_TASK_QUERY_PARAMS: {
      return Object.assign({}, state, {
        queryParams: { ...action.value }
      });
    }
    case REDUCERS.LOCATION.FETCH_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case REDUCERS.LOCATION.FETCH_DATA_SUCCESS:
      return { ...state, loading: false, tasks: [...action.payload] };
    case REDUCERS.LOCATION.FETCH_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case REDUCERS.TASK.SET_CURRENT_TASK: {
      return Object.assign({}, state, {
        currentTask: { ...state.currentTask, ...action.value }
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { task };
