import { createSlice } from '@reduxjs/toolkit';

import { formatArrayToOptions } from '../../helpers/common';

import {
  createTreatment,
  createTreatments,
  deleteTreatments,
  fetchTreatmentTypes,
  fetchTreatmentUOM,
  fetchTreatments
} from '../actions/treatment';

const treatmentSlice = createSlice({
  name: 'treatment',
  initialState: {
    treatments: [],
    treatmentUOM: [],
    treatmentTypes: [],
    treatmentUOMSelectOptions: [],
    treatmentTypesSelectOptions: [],
    selectedFilters: {},
    loading: false,
    error: null
  },
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      // Create Treatment
      .addCase(createTreatment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTreatment.fulfilled, (state, action) => {
        state.loading = false;
        state.treatments = [
          {
            ...action.payload,
            treatment_identifier: action.payload.identifier,
            treatment_type_name: state.treatmentTypes?.find(t => t.id === action.payload.treatment_type_id)
              .treatment_type_name
          },
          ...state.treatments
        ];
      })
      .addCase(createTreatment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create Treatments
      .addCase(createTreatments.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTreatments.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createTreatments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Treatments
      .addCase(fetchTreatments.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTreatments.fulfilled, (state, action) => {
        state.loading = false;
        state.treatments = action.payload;
      })
      .addCase(fetchTreatments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Treatments
      .addCase(deleteTreatments.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTreatments.fulfilled, (state, action) => {
        state.loading = false;
        state.treatments = state.treatments.filter(record => !action.payload?.includes(record.treatment_id));
      })
      .addCase(deleteTreatments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Treatment UOM
      .addCase(fetchTreatmentUOM.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTreatmentUOM.fulfilled, (state, action) => {
        state.loading = false;
        state.treatmentUOM = action.payload;
        state.treatmentUOMSelectOptions = formatArrayToOptions(action.payload, 'uom_description', 'uom_code');
      })
      .addCase(fetchTreatmentUOM.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Treatment Types
      .addCase(fetchTreatmentTypes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTreatmentTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.treatmentTypes = action.payload;
        state.treatmentTypesSelectOptions = formatArrayToOptions(action.payload, 'treatment_type_name', 'id');
      })
      .addCase(fetchTreatmentTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setSelectedFilters } = treatmentSlice.actions;
export default treatmentSlice.reducer;
