import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { FilterFilled } from '@ant-design/icons';
import { Button } from 'antd';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import moment from 'moment';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';
import { getFilterOptions, sortNumbers, sortStrings } from '../../helpers/filter';

import { DATE_FORMAT } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import { FEATURE_LIST } from '../../constants/subscription';

import { setNotificationList } from '../../redux/actions/notification';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import AppTag from '../../components/AppTag';
import Calendar from '../../components/Calendar';
import FeatureProvider from '../../components/FeatureProvider';
import Priority from '../../components/PriorityTag';
import Tasks from '../../components/Tasks';
import { OrderStatus, TopSection } from '../../components/dashboard-components';
import Filters from '../../components/filters/index.jsx';
import WeatherForecast from '../../components/weather-forecast';

import AppDropdown from '../ui-components/dropdown';

import { TABLE_COLUMNS } from '../../configs/subscriptions';
import './styles.scss';

const priority = {
  record: 'low',
  normal: 'medium',
  priority: 'high'
};

function RulePriority(props) {
  let priority = props.priority;
  const colors = {
    priority: 'red',
    high: 'red',
    normal: '#FFBF00',
    medium: '#FFBF00',
    low: '#CCCCCC',
    record: '#CCCCCC'
  };

  if (priority) {
    priority = priority.toLowerCase();

    switch (priority) {
      case 'record':
        priority = 'low';
        break;
      case 'normal':
        priority = 'medium';
        break;
      case 'priority':
        priority = 'high';
        break;
    }

    priority = priority.toLowerCase();
  }

  return <AppTag style={{ background: colors[priority] }}>{capitalize(priority)}</AppTag>;
}

class General extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      columnFilterOptions: {
        notificationPriorities: [],
        notificationTypes: [],
        rulePriorities: [],
        ruleStatuses: []
      },
      delete_modal: false,
      exception_modal: false,
      farmes: [],
      filterType: 'sex',
      filters: { farms: [] },
      filters_open: false,
      isLoading: true,
      isNotificationsLoading: true,
      isRulesLoading: true,
      livestockCount: [],
      livestockData: [],
      livestockTableData: [],
      livestock_exceptions: '',
      modal_message: '',
      modal_title: '',
      modal_type: '',
      notifications: [],
      rules: [],
      selected_geofence: '',
      selected_rules: [],
      totalNotificationCount: 0,
      totalRuleCount: 0,
      weatherInfo: null
    };

    this.filtersClose = this.filtersClose.bind(this);
    this.filtersUpdated = this.filtersUpdated.bind(this);
    this.getLivestockCountColumns = this.getLivestockCountColumns.bind(this);
    this.initActions = this.initActions.bind(this);
  }

  componentDidMount() {
    this.getFarms([], true);
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: (
            <Button
              color="default"
              size="small"
              variant="link"
              style={{ padding: 0 }}
              onClick={() => this.refreshFarmData()}>
              Refresh farm data
            </Button>
          )
        }
      ]
    });
  }

  filtersClose() {
    this.setState(state => ({ ...state, filters_open: false }));
  }

  sigleAction(id, type) {
    const modal_type = type === 'snoozable' ? 'Snooze' : 'Unsnooze';
    const selected_rules = [];

    selected_rules.push(id);

    this.setState({ selected_rules: selected_rules, modal_type: modal_type, selectAll: false });
    this.toggleSingleModal('delete_modal', modal_type);
  }

  toggleSingleModal(modal, type) {
    const title = type === 'Snooze' ? 'Snooze rule' : 'Unsnooze rule';
    const message =
      type === 'Snooze' ? 'Are you sure you want to snooze this rule?' : 'Are you sure you want to unsnooze this rule?';

    this.setState(state => ({
      ...state,
      [modal]: !state[modal],
      modal_type: type,
      modal_title: title,
      modal_message: message
    }));
  }

  async getFarms(farmIds = [], isLoadAll) {
    const response = await axios.get('farms');
    const farms = response.data.filter(item => isLoadAll || farmIds.includes(item.id));

    this.setFarmsInfo(farms);
    this.setState(prevState => ({ ...prevState, isLoading: false }));
  }

  setFarmsInfo(farms) {
    const [weatherFarm] = farms;
    const [coordinates] = weatherFarm?.master_geofence?.geo_json?.geometry?.coordinates[0] || [];
    const weatherInfo = weatherFarm && {
      headingTxt: `${weatherFarm.address.city}, ${weatherFarm.address.country}`,
      location: `${weatherFarm.address.city}, ${weatherFarm.address.country}`,
      latLng: {
        lat: coordinates[0],
        lng: coordinates[1]
      }
    };

    this.setState(
      {
        farmIds: farms.map(item => item.id),
        weatherInfo,
        filters: {
          farms: farms.map(item => ({
            value: item.id,
            label: item.name
          }))
        }
      },
      () => this.getDashboardData()
    );
  }

  getDashboardData() {
    this.getGeofences();
    this.getNotifications();
    this.getRules();
    this.getLivestockData();
  }

  async snoozeNotification(id) {
    await axios.put('notifications/' + id + '/snooze');
    this.getNotifications();
  }

  async clearNotification(id) {
    await axios.put('notifications/' + id + '/clear');
    const updatedNotificationList = this.props.notification.list.filter(item => item.id !== id);
    this.props.setNotificationList(updatedNotificationList);
    this.getNotifications();
  }

  async getNotifications() {
    const farmIds = this.state.farmIds;

    if (!farmIds.length) {
      this.setState({ notifications: [], totalNotificationCount: 0 });

      return;
    }

    const response = await axios.get('notifications', {
      params: {
        query: 'dashboard',
        farm_ids: farmIds
      }
    });

    this.setState(state => ({
      ...state,
      notifications: response.data.notifications,
      totalNotificationCount: response.data.length_72_hours,
      isNotificationsLoading: false,
      columnFilterOptions: {
        ...state.columnFilterOptions,
        notificationPriorities: getFilterOptions(response?.data?.notifications, 'priority'),
        notificationTypes: getFilterOptions(response?.data?.notifications, 'type')
      }
    }));
  }

  async getRules() {
    const farmIds = this.state.farmIds;

    if (!farmIds.length) {
      this.setState({ rules: [], totalRuleCount: 0, isRulesLoading: false });

      return;
    }

    const response = await axios.get('rules', {
      params: {
        query: 'dashboard',
        farm_ids: farmIds
      }
    });

    this.setState(state => ({
      ...state,
      rules: response.data.rules,
      totalRuleCount: response.data.active_rules_count,
      isRulesLoading: false,
      columnFilterOptions: {
        ...state.columnFilterOptions,
        rulePriorities: [...new Set(response.data.rules.map(item => item.data).map(d => d.action.value))].map(p => ({
          text: capitalize(priority[p]),
          value: p
        })),
        ruleStatuses: getFilterOptions(response?.data?.rules, 'snooze_status')
      }
    }));
  }

  async getGeofences() {
    const farmIds = this.state.farmIds;

    if (!farmIds.length) {
      this.setState({ livestockCount: [], activeLivestockCount: 0 });

      return;
    }

    const response = await axios.get('geofences', {
      params: {
        farm_ids: farmIds
      }
    });
    const activeLivestockCount = response.data.reduce((previousValue, currentValue) => {
      if (currentValue.is_master) return currentValue.active_livestock_count + (previousValue || 0);

      return previousValue || 0;
    }, 0);
    const unassignedLivestockCount = response.data.map(i => i.unassignedAnimals).filter(i => i !== undefined);
    const generalUnassignedAnimalsCount = unassignedLivestockCount.reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    this.setState({
      livestockCount: response.data,
      hasUnassignedAnimals: !!generalUnassignedAnimalsCount,
      activeLivestockCount
    });
  }

  showException(livestock_exceptions, selected_geofence) {
    const exception_modal = !this.state.exception_modal;

    this.setState(state => ({
      ...state,
      exception_modal: exception_modal,
      livestock_exceptions: livestock_exceptions,
      selected_geofence: selected_geofence
    }));
  }

  async getLivestockData() {
    const farmIds = this.state.farmIds;

    if (!farmIds.length) {
      this.setState({ livestockData: [], livestockTableData: [] });

      return;
    }

    const response = await axios.get('animals', {
      params: {
        dashboard_query: 'dashboard',
        farm_ids: farmIds
      }
    });

    const staticObj = { sex: [], stock_type: [], age_group: [] };

    if (response.data.sex && response.data.sex.length > 0) {
      response.data.sex.forEach(element => {
        let newArray = [];
        newArray.push(element.type);
        newArray.push(element.count);
        staticObj['sex'].push(newArray);
      });
    }

    if (response.data.age_group && response.data.age_group.length > 0) {
      response.data.age_group.forEach(element => {
        let newArray = [];
        newArray.push(element.type);
        newArray.push(element.count);
        staticObj['age_group'].push(newArray);
      });
    }

    if (response.data.stock_type && response.data.stock_type.length > 0) {
      response.data.stock_type.forEach(element => {
        let newArray = [];
        newArray.push(element.type);
        newArray.push(element.count);
        staticObj['stock_type'].push(newArray);
      });
    }

    this.setState({ livestockData: staticObj, livestockTableData: response.data });
  }

  async deleteRules() {
    if (this.state.modal_type === 'Archive') {
      await axios.delete('rules/bulk', {
        data: { ids: this.state.selected_rules, force: true }
      });
    } else if (this.state.modal_type === 'Snooze') {
      await axios.put('rules/snooze/bulk', {
        ids: this.state.selected_rules,
        force: true,
        seconds: 86400
      });
    } else {
      await axios.put('rules/snooze/bulk', {
        ids: this.state.selected_rules,
        force: true,
        seconds: -1
      });
    }

    this.getRules();
    this.setState({ delete_modal: false, selectAll: false, selected_rules: [] });
  }

  async refreshFarmData() {
    this.getDashboardData();
  }

  filtersUpdated = filters => {
    const farmIds = filters.farms.map(item => item.value);

    this.getFarms(farmIds);
  };

  getTrProps = (state, rowInfo) => {
    return rowInfo
      ? {
          style: {
            background: rowInfo.row.actions.clear ? '#f0f0f0' : '#ffffff'
          }
        }
      : {};
  };

  getLivestockCountColumns = columns => {
    const planType = this.props.subscription?.myPlan?.type;
    let animalsColumns = columns;

    if (!this.state.hasUnassignedAnimals) {
      animalsColumns = animalsColumns.filter(column => column.dataIndex !== 'unassignedAnimals');
    }

    if (TABLE_COLUMNS[FEATURE_LIST.LIVESTOCK_COUNT_WIDGET]?.[planType]?.length) {
      return animalsColumns.filter(column =>
        TABLE_COLUMNS[FEATURE_LIST.LIVESTOCK_COUNT_WIDGET]?.[planType]?.includes(column.dataIndex)
      );
    }

    return animalsColumns;
  };

  render() {
    const commonColumnProperties = {
      ellipsis: true,
      sortDirections: ['ascend', 'descend']
    };

    const Livestockcolumns = [
      {
        ...commonColumnProperties,
        title: 'Geofence Name',
        dataIndex: 'geofenceName',
        width: COLUMN_SIZE.LG,
        render: (value, record) => <Link to={`geofence/${record.id}`}>{record.name}</Link>,
        sorter: (a, b) => sortStrings(a.name, b.name)
      },
      {
        ...commonColumnProperties,
        title: 'Assigned Livestock',
        dataIndex: 'active_livestock_count',
        width: COLUMN_SIZE.MD,
        sorter: (a, b) => sortNumbers(a.active_livestock_count, b.active_livestock_count)
      },
      {
        ...commonColumnProperties,
        title: 'Expected Tracked Livestock',
        dataIndex: 'livestock_with_tag',
        width: COLUMN_SIZE.MD,
        sorter: (a, b) => sortNumbers(a.livestock_with_tag, b.livestock_with_tag)
      },
      {
        ...commonColumnProperties,
        title: 'Actual Tracked Livestock',
        dataIndex: 'actual_livestock',
        width: COLUMN_SIZE.MD,
        sorter: (a, b) => sortNumbers(a.actual_livestock, b.actual_livestock)
      },
      {
        ...commonColumnProperties,
        title: 'Difference',
        dataIndex: 'livestock_difference',
        width: COLUMN_SIZE.MD,
        sorter: (a, b) => sortNumbers(a.livestock_difference, b.livestock_difference)
      },
      {
        ...commonColumnProperties,
        title: 'Unassigned Livestock',
        dataIndex: 'unassignedAnimals',
        width: COLUMN_SIZE.MD,
        sorter: (a, b) => sortNumbers(a.unassignedAnimals, b.unassignedAnimals)
      },
      {
        title: 'Exceptions',
        dataIndex: 'exceptionsId',
        ellipsis: true,
        width: COLUMN_SIZE.SM,
        render: (value, record) => {
          const livestockExceptions = record.livestock_exceptions;
          const hasLivestockExceptions = livestockExceptions ? livestockExceptions.length : null;
          const geofencename = record.name;
          const exceptions = hasLivestockExceptions
            ? livestockExceptions
                .map(x => (
                  <Link key={x.id} to={`/animal/${x.id}`}>
                    {x.identifier}
                  </Link>
                ))
                .reduce((prev, curr) => [prev, ', ', curr])
            : '';
          return exceptions !== '' ? (
            exceptions.toString().split(',').length > 1 ? (
              <Button
                className="p-0"
                color="primary"
                variant="link"
                style={{ height: 'auto' }}
                onClick={() => this.showException(exceptions, geofencename)}>
                View all
              </Button>
            ) : (
              exceptions
            )
          ) : (
            ''
          );
        }
      }
    ];

    const notificationsColumns = [
      {
        ...commonColumnProperties,
        title: 'Notification ID',
        dataIndex: 'id',
        width: COLUMN_SIZE.SM,
        render: (value, record) => <Link to={`notification/${record.id}`}>{record.identifier}</Link>,
        sorter: (a, b) => sortStrings(a.identifier, b.identifier)
      },
      {
        ...commonColumnProperties,
        title: 'Notification Priority',
        dataIndex: 'priority',
        filters: this.state.columnFilterOptions.notificationPriorities,
        width: COLUMN_SIZE.MD,
        onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.priority : record?.priority === value),
        render: (value, record) => <Priority priority={record.priority} isClear={record.cleared_at}></Priority>,
        sorter: (a, b) => sortNumbers(a.priority_index, b.priority_index)
      },
      {
        ...commonColumnProperties,
        title: 'Type',
        dataIndex: 'type',
        filters: this.state.columnFilterOptions.notificationTypes,
        width: COLUMN_SIZE.MD,
        onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.type : record?.type === value),
        render: value => capitalize(value),
        sorter: (a, b) => sortStrings(a.type, b.type)
      },
      {
        ...commonColumnProperties,
        title: 'Rule Name',
        dataIndex: 'ruleName',
        width: COLUMN_SIZE.XL,
        render: (value, record) => record?.rule?.name,
        sorter: (a, b) => sortStrings(a?.rule?.name, b?.rule?.name)
      },
      {
        ...commonColumnProperties,
        title: 'Date',
        dataIndex: 'created_at',
        width: COLUMN_SIZE.MD,
        render: (value, record) => moment(value).format(DATE_FORMAT.DATETIME)
      },
      {
        title: 'Snooze/Clear',
        dataIndex: 'actions',
        align: 'center',
        ellipsis: true,
        width: COLUMN_SIZE.XS,
        render: (value, record) => (
          <>
            <i
              onClick={() => this.snoozeNotification(record.id)}
              className={'not-action mdi mdi-alarm ' + record.status}></i>
            <i
              onClick={() => this.clearNotification(record.id)}
              className={'not-action clear mdi mdi-check-circle-outline ' + !!record.cleared_at}></i>
          </>
        )
      }
    ];

    const rulePriorityIndex = {
      high: 2,
      medium: 1,
      low: 0,
      priority: 2,
      normal: 1,
      record: 0
    };

    const rulesColumns = [
      {
        ...commonColumnProperties,
        title: 'Rule ID',
        dataIndex: 'rulenamId',
        width: COLUMN_SIZE.MD,
        render: (value, record) => <Link to={`rule/${record.id}`}>{record.identifier}</Link>,
        sorter: (a, b) => sortStrings(a.identifier, b.identifier)
      },
      {
        ...commonColumnProperties,
        title: 'Notification Count',
        dataIndex: 'triggers_count',
        width: COLUMN_SIZE.MD,
        sorter: (a, b) => sortNumbers(a.triggers_count, b.triggers_count)
      },
      {
        ...commonColumnProperties,
        title: 'Rule Priority',
        dataIndex: 'rpriority',
        filters: this.state.columnFilterOptions.rulePriorities,
        width: COLUMN_SIZE.MD,
        onFilter: (value, record) =>
          value === BLANK_FILTER_TEXT ? !record?.data.action.value : record?.data.action.value === value,
        render: (value, record) => <RulePriority priority={record.data.action.value || ''}></RulePriority>,
        sorter: (a, b) => sortNumbers(rulePriorityIndex[a.data.action.value], rulePriorityIndex[b.data.action.value])
      },
      {
        ...commonColumnProperties,
        title: 'Rule Status',
        dataIndex: 'rulestatusid',
        filters: this.state.columnFilterOptions.ruleStatuses,
        width: COLUMN_SIZE.LG,
        onFilter: (value, record) =>
          value === BLANK_FILTER_TEXT ? !record?.snooze_status : record?.snooze_status === value,
        render: (value, record) => (record.is_snoozed ? 'Snoozed' : 'Active'),
        sorter: (a, b) => {
          const strA = a.is_snoozed ? 'Snoozed' : 'Active';
          const strB = b.is_snoozed ? 'Snoozed' : 'Active';

          return sortStrings(strA, strB);
        }
      },
      {
        title: 'Snooze',
        dataIndex: 'actions',
        align: 'center',
        ellipsis: true,
        fixed: 'right',
        width: COLUMN_SIZE.XS,
        render: (value, record) => (
          <i
            style={{ cursor: 'pointer' }}
            onClick={() => this.sigleAction(record.id, record.is_snoozed ? 'snoozed' : 'snoozable')}
            className={`rule_not-action mdi mdi-alarm ${record.is_snoozed ? 'snoozed' : 'snoozable'}`}></i>
        )
      }
    ];

    return (
      <>
        <Row className="dashboard-title-row">
          <Col xs="12" md="12" lg="6">
            <h4 className="">Dashboard</h4>
          </Col>
          <Col xs="12" md="12" lg="6" className="d-flex justify-content-end align-items-center h-100">
            <AppDropdown
              label="Actions"
              items={this.state.actions}
              handleClick={action => {
                if (action.handler) {
                  return action.handler();
                }
              }}
            />

            <FeatureProvider name={FEATURE_LIST.FARM_FILTER}>
              <Button
                className="ml-2"
                color="primary"
                size="large"
                variant="solid"
                icon={<FilterFilled />}
                onClick={() => this.setState({ ...this.state, filters_open: !this.state.filters_open })}>
                Filters
              </Button>
            </FeatureProvider>

            <Filters
              farms={this.state.filters.farms}
              open={this.state.filters_open}
              onClose={this.filtersClose}
              updateFilters={this.filtersUpdated}
              filter_types={['farm']}
            />
          </Col>
        </Row>

        <TopSection
          farm={this.state.filters.farm}
          ruleCount={this.state.totalRuleCount}
          totalNotication={this.state.totalNotificationCount}
          activeLivestock={this.state.activeLivestockCount}
          history={this.props.history}
        />

        <Row>
          <Col sm={12} lg={6} className="comman-col-box LivestockCount-widget-col">
            <div className="cols-wrap">
              <div className="heading-with-icons">
                <h4>Livestock Count</h4>
                <div className="close-full-btns">
                  <i className="fa fa-window-maximize" aria-hidden="true"></i>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>

              <div className="mx-2">
                <AppTable
                  headerClass="py-2"
                  baseColumns={this.getLivestockCountColumns(Livestockcolumns)}
                  dataSource={this.state.livestockCount}
                  loading={this.state.isLoading}
                  pageSize={10}
                  rowsSelectable={false}
                  searchable={false}
                  settings={false}
                  tableMaxHeight="249px"
                />
              </div>
            </div>
          </Col>
          <FeatureProvider name={FEATURE_LIST.WEATHER_WIDGET}>
            <Col sm={12} lg={6} className="comman-col-box Weather-widget-col">
              <div className="cols-wrap">
                <div className="heading-with-icons">
                  <h4>Weather</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                {this.state.weatherInfo ? (
                  <WeatherForecast
                    apikey="a7d27f9bd8fad47e927c8936a8b7809b"
                    headingTxt={this.state.weatherInfo.headingTxt || null}
                    location={this.state.weatherInfo.location || null}
                    latLng={this.state.weatherInfo.latLng || null}
                  />
                ) : (
                  <div
                    style={{
                      padding: '40px',
                      textAlign: 'center',
                      color: 'rgba(0,0,0,0.5)'
                    }}>
                    No weather data
                  </div>
                )}
              </div>
            </Col>
          </FeatureProvider>

          <FeatureProvider name={FEATURE_LIST.LAST_NOTIFICATIONS_WIDGET}>
            <Col sm={12} lg={6} className="comman-col-box Notifications-widget-col">
              <div className="cols-wrap">
                <div className="heading-with-icons">
                  <h4>Last 10 Notifications</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>

                <div className="mx-2">
                  <AppTable
                    headerClass="py-2"
                    baseColumns={notificationsColumns}
                    dataSource={this.state.notifications}
                    loading={this.state.isNotificationsLoading}
                    pageSize={10}
                    rowClassName={record => (record.cleared_at ? 'row-secondary' : '')}
                    rowsSelectable={false}
                    searchable={false}
                    settings={false}
                  />
                </div>
              </div>
            </Col>
          </FeatureProvider>

          <FeatureProvider name={FEATURE_LIST.LIVESTOCK_ANALYSIS_WIDGET}>
            <Col lg={6} className="comman-col-box LivestockBreakdown-widget-col">
              <div className="cols-wrap">
                <div className="heading-with-icons">
                  <h4>Livestock Analysis</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>

                {this.state.livestockData['sex'] && this.state.livestockData['sex'].length > 0 ? (
                  <OrderStatus
                    livestocKData={this.state.livestockData}
                    livestockTableData={this.state.livestockTableData}
                  />
                ) : (
                  <div
                    style={{
                      padding: '40px',
                      textAlign: 'center',
                      color: 'rgba(0,0,0,0.5)'
                    }}>
                    No livestock data
                  </div>
                )}
              </div>
            </Col>
          </FeatureProvider>

          <FeatureProvider name={FEATURE_LIST.CALENDAR_WIDGET}>
            <Col lg={6} className="comman-col-box calendar-widget-col">
              <div className="cols-wrap">
                <div className="heading-with-icons">
                  <h4>Calendar</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                <Calendar step={90} />
              </div>
            </Col>
          </FeatureProvider>

          <Col sm={12} lg={6} className="comman-col-box TopRules-widget-col">
            <div className="cols-wrap">
              <FeatureProvider name={FEATURE_LIST.RULES_WIDGET}>
                <div className="heading-with-icons">
                  <h4>Top 5 Rules</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>

                <div className="mx-2">
                  <AppTable
                    headerClass="py-2"
                    baseColumns={rulesColumns}
                    dataSource={this.state.rules}
                    loading={this.state.isRulesLoading}
                    pageSize={10}
                    rowsSelectable={false}
                    searchable={false}
                    settings={false}
                  />
                </div>
              </FeatureProvider>

              <FeatureProvider name={FEATURE_LIST.TASKS_WIDGET}>
                <div className="heading-with-icons">
                  <h4>Top 10 Tasks</h4>
                  <div className="close-full-btns">
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
                <Tasks queryParameters={{ limit: 10 }} />
              </FeatureProvider>
            </div>
          </Col>
        </Row>

        <AppModal
          confirmButtonColor="danger"
          confirmButtonHidden={true}
          confirmButtonText="Archive"
          isOpen={this.state.exception_modal}
          title={`Livestock Exceptions for ${this.state.selected_geofence}`}
          handleCancel={() => this.showException()}>
          <div className="py-4">{this.state.livestock_exceptions}</div>
        </AppModal>

        <AppModal
          isOpen={this.state.delete_modal}
          confirmButtonText={this.state.modal_type}
          title={this.state.modal_title}
          handleCancel={() => this.toggleSingleModal('delete_modal')}
          handleConfirm={() => this.deleteRules()}>
          <div className="py-4">{this.state.modal_message}</div>
        </AppModal>
      </>
    );
  }
}

export default connect(
  state => state,
  dispatch => ({
    setNotificationList: data => {
      dispatch(setNotificationList(data));
    }
  })
)(General);
