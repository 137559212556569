import { REDUCERS } from '../../constants/reducers';

const initialState = {
  breedingEventStatusTypesError: null,
  breedingEventStatusTypesLoading: false,
  breedingEventStatusTypesSelectOptions: [],
  breedingEventTypesError: null,
  breedingEventTypesLoading: false,
  breedingEventTypesSelectOptions: [],
  data: null,
  error: null,
  loading: false,
  selectedFilters: {}
};

const breeding = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.BREEDING.SET_SELECTED_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        selectedFilters: action.value
      });
    }
    case REDUCERS.BREEDING.DELETE_BREEDINGS:
      return {
        ...state,
        data: state.data.filter(record => !action.payload.includes(record.breeding_id))
      };
    case REDUCERS.BREEDING.FETCH_BREEDING_REQUEST:
      return { ...state, loading: true, data: null, error: null };
    case REDUCERS.BREEDING.FETCH_BREEDING_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case REDUCERS.BREEDING.FETCH_BREEDING_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case REDUCERS.BREEDING.FETCH_BREEDING_EVENT_TYPES_REQUEST:
      return { ...state, breedingEventTypesLoading: true, breedingEventTypesError: null };
    case REDUCERS.BREEDING.FETCH_BREEDING_EVENT_TYPES_SUCCESS:
      return { ...state, breedingEventTypesLoading: false, ...action.payload };
    case REDUCERS.BREEDING.FETCH_BREEDING_EVENT_TYPES_FAILURE:
      return { ...state, breedingEventTypesLoading: false, breedingEventTypesError: action.payload };

    case REDUCERS.BREEDING.FETCH_BREEDING_EVENT_STATUS_TYPES_REQUEST:
      return { ...state, breedingEventStatusTypesLoading: true, breedingEventStatusTypesError: null };
    case REDUCERS.BREEDING.FETCH_BREEDING_EVENT_STATUS_TYPES_SUCCESS:
      return { ...state, breedingEventStatusTypesLoading: false, ...action.payload };
    case REDUCERS.BREEDING.FETCH_BREEDING_EVENT_STATUS_TYPES_FAILURE:
      return { ...state, breedingEventStatusTypesLoading: false, breedingEventStatusTypesError: action.payload };
    default:
      return state;
  }
};

export { breeding };
