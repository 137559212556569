import { useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { Tabs } from 'antd';

import GatewayIcon from '../../components/GatewayIcon';
import GatewaysTable from '../../components/Tables/GatewaysTable';
import TagsTable from '../../components/Tables/TagsTable';
import TagIcon from '../../components/TagIcon';

export default function Devices() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const tab = queryParams.get('tab');
  const items = [
    {
      key: 'tags',
      label: `Tags`,
      children: <TagsTable />,
      icon: <TagIcon />
    },
    {
      key: 'gateways',
      label: `Gateways`,
      children: <GatewaysTable />,
      icon: <GatewayIcon style={{ width: '14px' }} />
    }
  ];

  return (
    <div className="card p-2">
      <div>
        <h4 className="mb-0">Kraal Devices</h4>
        <Breadcrumb>
          <BreadcrumbItem>List of Kraal Tags and Gateways</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Tabs defaultActiveKey={tab || 'tags'} items={items} />
    </div>
  );
}
