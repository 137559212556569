import axios from '../../axios';

const service = {
  fetchAnimals() {
    return axios({ method: 'get', url: '/animals' });
  },
  async updateAnimals(payload) {
    return axios({
      method: 'put',
      url: '/animals',
      data: payload
    });
  }
};

export default service;
