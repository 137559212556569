import { createAsyncThunk } from '@reduxjs/toolkit';

import TreatmentService from '../../services/treatment';

export const fetchTreatments = createAsyncThunk('treatments/fetchTreatments', async (params, { rejectWithValue }) => {
  try {
    const response = await TreatmentService.fetchTreatments(params);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch treatments');
  }
});

export const fetchTreatmentUOM = createAsyncThunk('treatments/fetchTreatmentUOM', async (_, { rejectWithValue }) => {
  try {
    const response = await TreatmentService.fetchTreatmentUOM();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch treatment UOM');
  }
});

export const fetchTreatmentTypes = createAsyncThunk(
  'treatments/fetchTreatmentTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await TreatmentService.fetchTreatmentTypes();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch treatment types');
    }
  }
);

export const deleteTreatments = createAsyncThunk('treatments/deleteTreatments', async (ids, { rejectWithValue }) => {
  try {
    await TreatmentService.deleteTreatments(ids);

    return ids;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to delete treatment');
  }
});

export const createTreatment = createAsyncThunk('treatments/createTreatment', async (data, { rejectWithValue }) => {
  try {
    const response = await TreatmentService.createTreatment(data);

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to create treatment');
  }
});

export const createTreatments = createAsyncThunk(
  'treatments/createTreatments',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await TreatmentService.createTreatments(data);
      dispatch(fetchTreatments());
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create treatment');
    }
  }
);
