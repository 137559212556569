export const COLUMN_SIZE = {
  XXL: 250,
  XL: 190,
  LG: 160,
  MD: 130,
  SM: 110,
  XS: 80
};

export const TABLE_SIZE = {
  LG: 'large',
  MD: 'middle',
  SM: 'small'
};

export const FONT_SIZE = {
  LG: 17,
  MD: 15,
  SM: 14
};
