import { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';

import classnames from 'classnames';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';

import messages from '../../constants/messages';

import Alerts from '../../components/Alerts';
import PrevNextButtons from '../../components/PrevNextButtons';
import { errorToastHandler } from '../../components/action_notifier';
import StatusIndicator from '../../components/statusIndicator';

import AppDropdown from '../ui-components/dropdown';

export default class Gateway extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      delete_modal: false,
      editData: {},
      editing: false,
      gateway: {},
      selected_geofences: []
    };

    this.enableEditing = this.enableEditing.bind(this);
    this.initActions = this.initActions.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveGateway = this.saveGateway.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    //TODO: Get tag
    this.getGateway();
    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: 'Edit Kraal Gateway',
          isVisible: () => true,
          handler: () => this.enableEditing()
        }
      ]
    });
  }

  async getGateway() {
    const gatewayId = this.props.match.params.id;
    const response = await axios.get('gateways/' + gatewayId);

    if (response.status === 200) this.setState(state => ({ ...state, gateway: response.data }));
  }

  onCancelEdit = (value, field) => {
    this.setState(state => ({ ...state, editData: this.state.gateway, [field]: value }));
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  enableEditing() {
    this.setState(state => ({ ...state, editing: true, editData: { ...this.state.gateway } }));
  }

  onChange = (value, field) => {
    this.setState(state => ({ ...state, editData: { ...state.editData, [field]: value } }));
  };

  async saveGateway() {
    const gatewayId = this.props.match.params.id;
    const editData = this.state.editData;

    try {
      const response = await axios.put('gateways/' + gatewayId, {
        id: editData.id,
        name: editData.name
      });

      if (response.status === 200) {
        this.getGateway();
        this.setState({ ...this.state, editing: false });
      }
    } catch (error) {
      errorToastHandler(messages.DEFAULT_ERROR);
    }
  }

  render() {
    if (!this.state.gateway.id) return null;

    const columns = [
      {
        Header: 'Kraal tag',
        id: 'tag',
        accessor: d => ({ status: d.status, identifier: d.identifier, id: d.id }),
        headerClassName: 'wordwrap',
        Cell: props => <Link to={`/tag/${props.value.id}`}>{props.value.identifier}</Link>
      }
    ];

    return (
      <>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4>
              {this.state.editing && 'Edit '}Gateway {this.state.gateway.name || this.state.gateway.appeui}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/devices?tab=gateways">List of Kraal Gateways</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {this.state.editing && 'Edit '}Gateway {this.state.gateway.name || this.state.gateway.appeui}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <PrevNextButtons
              nextId={this.state.gateway.next_id}
              prevId={this.state.gateway.prev_id}
              handlePrev={() => this.props.history.push(`/tracking/${this.state.gateway.prev_id}`)}
              handleNext={() => this.props.history.push(`/tracking/${this.state.gateway.next_id}`)}
            />

            {this.state.editing && (
              <>
                <Button color="primary" className="float-right" onClick={() => this.saveGateway()}>
                  Save
                </Button>
                <Button className="outline float-right" onClick={() => this.onCancelEdit(false, 'editing')}>
                  Cancel
                </Button>
              </>
            )}

            {!this.state.editing && (
              <AppDropdown
                className="float-right"
                label="Actions"
                items={this.state.actions.filter(item => item.isVisible())}
                handleClick={action => action.handler()}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="p-30  global-heading-tag">
                    <h5 className="details-title">Gateway {this.state.gateway.name || this.state.gateway.appeui}</h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12" lg="12">
                  <Table>
                    <tbody>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway ID</b>
                        </td>
                        <td>{this.state.gateway.identifier || '/'}</td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway Name</b>
                        </td>
                        <td>
                          {!this.state.editing && <span>{this.state.gateway.name || '/'}</span>}
                          {this.state.editing && (
                            <Input
                              name={'name'}
                              defaultValue={this.state.editData.name}
                              onChange={e => this.onChange(e.target.value, e.target.name)}
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Gateway Connection Status</b>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <StatusIndicator key={this.state.gateway.id} status={this.state.gateway.status} />
                            {capitalize(this.state.gateway.status)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Connected Kraal Tags</b>
                        </td>
                        <td>{this.state.gateway.tags.length}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {this.state.gateway.tags && (
          <Row>
            <Col xs="12" md="12" lg="12">
              <Card>
                <Row>
                  <Col xs="12" md="12" lg="12">
                    <div className="p-30 global-heading-tag kraal-listing">
                      <h4 className="">Connected Kraal Tags</h4>
                      <p className="text-muted">Kraal tags connected to {this.state.gateway.identifier}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="12" lg="12">
                    <ReactTable
                      showPagination={this.state.gateway.tags.length > 0}
                      minRows={0}
                      data={this.state.gateway.tags}
                      columns={columns}
                      resizable={true}
                      defaultPageSize={25}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs="12" md="12" lg="12" className="global-table">
            <Card>
              <Row className="pad-10 pb-0">
                <Col sm="12" className="mb-0">
                  <h5>History</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Nav tabs className="fancy-tabs">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => this.toggle('1')}>
                        Notifications
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <TabContent className="no-bg" activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <Alerts
                        type="full"
                        query={{ gateway_ids: [this.props.match.params.id] }}
                        filter={{ filter: data => data.type === 'gateway' }}
                      />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
