import axios from '../../axios';

const service = {
  createMeasurement(data) {
    return axios.request({ method: 'post', url: '/measures', data });
  },

  createMeasurements(data) {
    return axios.request({ method: 'post', url: '/measures/bulk', data });
  },

  updateMeasurement(id, data) {
    return axios.request({ method: 'put', url: `/measures/${id}`, data });
  },

  deleteMeasurements(ids) {
    return axios({ method: 'delete', url: `/measures/destroy_bulk/`, data: { ids } });
  },

  fetchMeasurements(params) {
    return axios({ method: 'get', url: '/measures', params });
  },

  fetchAnimalMeasurements(id, params) {
    return axios({ method: 'get', url: `/measures/${id}`, params });
  }
};

export default service;
