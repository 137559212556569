import { REDUCERS } from '../../constants/reducers';

const initialState = {
  data: null,
  loading: false,
  error: null,
  filterOptions: [],
  selectOptions: []
};

const matingEvent = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.MATING_EVENT.FETCH_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case REDUCERS.MATING_EVENT.FETCH_DATA_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case REDUCERS.MATING_EVENT.FETCH_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export { matingEvent };
