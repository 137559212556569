export const AGENDA = 'customAgenda';
export const TOOLBAR_OPTIONS = [
  {
    label: 'Day',
    value: 'day'
  },
  {
    label: 'Week',
    value: 'week'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Event List',
    value: 'customAgenda'
  }
];
