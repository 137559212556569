import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getUsers } from '../../../redux/actions/user';

import Calendar from '../../../components/Calendar';
import LayoutTemplate from '../../../components/LayoutTemplate';

import CalendarHeaderOptions from './CalendarHeaderOptions';
import './styles.scss';

function MyCalendar() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div className="calendar-page">
      <LayoutTemplate title="Calendar" headerOptions={<CalendarHeaderOptions />} content={<Calendar />} />
    </div>
  );
}

export default memo(MyCalendar);
