import { formatArrayToOptions } from '../../helpers/common';

import { REDUCERS } from '../../constants/reducers';

import { BreedingService } from '../../services';

export const fetchBreedingRequest = () => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_REQUEST
});

export const fetchBreedingSuccess = data => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_SUCCESS,
  payload: data
});

export const fetchBreedingFailure = error => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_FAILURE,
  payload: error
});

export const fetchBreedingEventTypesRequest = () => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_EVENT_TYPES_REQUEST
});

export const fetchBreedingEventTypesSuccess = data => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_EVENT_TYPES_SUCCESS,
  payload: data
});

export const fetchBreedingEventTypesFailure = error => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_EVENT_TYPES_FAILURE,
  payload: error
});

export const fetchBreedingEventStatusTypesRequest = () => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_EVENT_STATUS_TYPES_REQUEST
});

export const fetchBreedingEventStatusTypesSuccess = data => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_EVENT_STATUS_TYPES_SUCCESS,
  payload: data
});

export const fetchBreedingEventStatusTypesFailure = error => ({
  type: REDUCERS.BREEDING.FETCH_BREEDING_EVENT_STATUS_TYPES_FAILURE,
  payload: error
});

export const setSelectedFilters = value => ({
  type: REDUCERS.BREEDING.SET_SELECTED_FILTERS,
  value
});

export const deleteRecords = ids => ({
  type: REDUCERS.BREEDING.DELETE_BREEDINGS,
  payload: ids
});

export const createBreedings = data => {
  return async dispatch => {
    try {
      await BreedingService.createBreedings(data);
      dispatch(fetchBreeding());
    } catch (error) {
      dispatch(fetchBreedingFailure(error.message));
    }
  };
};

export const fetchBreeding = params => {
  return async dispatch => {
    dispatch(fetchBreedingRequest());

    try {
      const { data } = await BreedingService.fetchBreeding(params);

      dispatch(fetchBreedingSuccess(data));
    } catch (error) {
      dispatch(fetchBreedingFailure(error.message));
    }
  };
};

export const fetchBreedingEventTypes = () => {
  return async (dispatch, getState) => {
    const { breedingEventTypesSelectOptions } = getState().breeding;

    if (breedingEventTypesSelectOptions?.length) return;

    dispatch(fetchBreedingEventTypesRequest());

    try {
      const { data } = await BreedingService.fetchBreedingEventTypes();
      const payload = {
        breedingEventTypesSelectOptions: formatArrayToOptions(data, 'breeding_event_name', 'id')
      };
      dispatch(fetchBreedingEventTypesSuccess(payload));
    } catch (error) {
      dispatch(fetchBreedingEventTypesFailure(error.message));
    }
  };
};

export const fetchBreedingEventStatusTypes = () => {
  return async (dispatch, getState) => {
    const { breedingEventStatusTypesSelectOptions } = getState().breeding;

    if (breedingEventStatusTypesSelectOptions?.length) return;

    dispatch(fetchBreedingEventStatusTypesRequest());

    try {
      const { data } = await BreedingService.fetchBreedingEventStatus();
      const payload = {
        breedingEventStatusTypesSelectOptions: formatArrayToOptions(data, 'breeding_status_name', 'id')
      };
      dispatch(fetchBreedingEventStatusTypesSuccess(payload));
    } catch (error) {
      dispatch(fetchBreedingEventStatusTypesFailure(error.message));
    }
  };
};

export const deleteBreedings = ids => {
  return async dispatch => {
    try {
      await BreedingService.deleteBreedings(ids);
      dispatch(deleteRecords(ids));
    } catch (error) {
      dispatch(fetchBreedingFailure(error.message));
    }
  };
};
