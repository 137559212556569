import { REDUCERS } from '../../constants/reducers';

const initialState = {
  selectedFilters: {}
};

const group = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.GROUP.SET_SELECTED_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        selectedFilters: action.value
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { group };
