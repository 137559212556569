import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Button } from 'antd';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import moment from 'moment';
import * as XLSX from 'xlsx';

import axios from '../../axios';

import { capitalize, downloadCSV } from '../../helpers/common';
import { getFilterOptions, sortDates, sortNumbers, sortStrings } from '../../helpers/filter';

import { DATE_FORMAT, EDIT_COMPONENT_TYPE, KEY_PRESS_CODE, SEARCH_DEBOUNCE_DELAY } from '../../constants/common';
import { BLANK_FILTER_TEXT } from '../../constants/livestock';
import messages from '../../constants/messages';
import { TREATMENT_CSV_TEMPLATE } from '../../constants/templates';

import {
  createTreatment,
  createTreatments,
  deleteTreatments,
  fetchTreatmentTypes,
  fetchTreatmentUOM,
  fetchTreatments
} from '../../redux/actions/treatment';
import { setSelectedFilters } from '../../redux/reducers/treatment';

import AppModal from '../../components/AppModal';
import AppTable from '../../components/AppTable';
import { COLUMN_SIZE } from '../../components/AppTable/constants';
import UploadModal from '../../components/Modals/UploadModal';

import { errorToastHandler, infoToastHandler, successToastHandler } from '../action_notifier';
import CreateTreatmentModal from './CreateTreatmentModal';

function TreatmentsTable(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { treatments, treatmentUOMSelectOptions, treatmentTypesSelectOptions, selectedFilters, loading, error } =
    useSelector(state => state.treatment);
  const [actions, setActions] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [state, setState] = useState({
    bulk_modal: false,
    columnFilterOptions: {
      breeds: [],
      sex: [],
      treatmentTypes: []
    },
    filters: { farms: [], geofences: [], labels: [] },
    query: '',
    selectedIds: []
  });

  useEffect(() => {
    getData();
    dispatch(fetchTreatmentUOM());
    dispatch(fetchTreatmentTypes());
  }, [dispatch]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      columnFilterOptions: {
        ...prevState.columnFilterOptions,
        breeds: getFilterOptions(treatments, 'breed'),
        sex: getFilterOptions(treatments, 'sex'),
        treatmentTypes: getFilterOptions(treatments, 'treatment_type_name')
      }
    }));
  }, [treatments]);

  useEffect(() => {
    initActions();
  }, [state.selectedIds, selectedFilters]);

  function initActions() {
    setActions([
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!params.id && !state.selectedIds?.length}
            style={{ padding: 0 }}
            onClick={() => toggleModal('bulk_modal')}>
            Add treatment
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            style={{ padding: 0 }}
            onClick={() => toggleModal('csv_modal')}>
            Upload treatment
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!state.selectedIds?.length}
            style={{ padding: 0 }}
            onClick={() => downloadCSVFile()}>
            Download treatment list
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!state.selectedIds?.length}
            style={{ padding: 0 }}
            onClick={() => toggleModal('delete_modal')}>
            Archive treatment
          </Button>
        )
      },
      {
        label: (
          <Button
            color="default"
            size="small"
            variant="link"
            disabled={!Object.values(selectedFilters)?.length}
            style={{ padding: 0 }}
            onClick={() => clearFilters()}>
            Clear all filters
          </Button>
        )
      }
    ]);
  }

  function prepareTreatmentData(data, livestockId) {
    return {
      ...data,
      livestock_id: parseInt(livestockId),
      repeat_date: data.repeat_date ? data.repeat_date.format(DATE_FORMAT.ISO_8601) : '',
      review_date: data.review_date ? data.review_date.format(DATE_FORMAT.ISO_8601) : '',
      treatment_date: data.treatment_date ? data.treatment_date.format(DATE_FORMAT.ISO_8601) : ''
    };
  }

  function createTreatmentForCurrentAnimal(data) {
    const newData = prepareTreatmentData(data, params.id);

    dispatch(createTreatment(newData));
  }

  function createTreatmentForSelectedAnimals(data) {
    const records = treatments?.filter(item => state.selectedIds.includes(item.id));
    const newData = records.map(record => prepareTreatmentData(data, record.livestock_id));

    dispatch(createTreatments(newData));
  }

  function handleCreateTreatment(data) {
    if (!state.selectedIds?.length && !params.id) return;

    state.selectedIds?.length && !params.id
      ? createTreatmentForSelectedAnimals(data)
      : createTreatmentForCurrentAnimal(data);

    toggleModal('bulk_modal');
  }

  async function handleChangeTableData(updatedData) {
    const record = updatedData.at(0);

    if (!record.treatment_id) {
      infoToastHandler('Please, add treatment first!');

      return;
    }

    const initRecord = treatments?.find(i => i.id === record.id);
    const newData = {
      ...record,
      dosage_uom: record.dosage_uom?.label,
      id: isNaN(record.treatment_id) ? null : record.treatment_id,
      is_positive: record.is_positive ? record.is_positive?.value : initRecord.is_positive,
      repeat_date: dayjs.isDayjs(record.repeat_date) ? dayjs(record.repeat_date).format(DATE_FORMAT.ISO_8601) : '',
      review_date: dayjs.isDayjs(record.review_date) ? dayjs(record.review_date).format(DATE_FORMAT.ISO_8601) : '',
      treatment_date: dayjs(record.treatment_date || initRecord.treatment_date).format(DATE_FORMAT.ISO_8601)
    };

    const response = await axios.put(`treatment/${record.treatment_id}`, newData);

    if (response.status === 200) {
      await getData();
      successToastHandler(messages.UPDATED);
    }
  }

  function deleteRecords() {
    const treatmentIds = treatments?.filter(d => state.selectedIds.includes(d.id)).map(i => i.treatment_id);
    dispatch(deleteTreatments(treatmentIds));

    if (!params.id) getData();
    if (!error) successToastHandler(messages.ARCHIVED);

    toggleModal('delete_modal');
  }

  async function downloadCSVFile() {
    if (state?.selectedIds?.length) {
      try {
        const query = `treatment_ids=${state?.selectedIds}`;
        const response = await axios.get(`/treatment/export?${query}`, {
          responseType: 'blob'
        });
        downloadCSV(response.data, `Treatments_${moment().format(DATE_FORMAT.DATE)}.xlsx`);
      } catch (error) {
        errorToastHandler(messages.FAILED_ON_DOWNLOAD_CSV);
      }
    }
  }

  async function getData() {
    const animalId = params.id ? +params.id : null;
    const reqParams = animalId
      ? {
          livestock_ids: animalId,
          treatment_ids: animalId
        }
      : {
          query: state.query,
          farm_ids: state.filters?.farms?.map(x => x.value),
          label_ids: state.filters?.labels?.map(x => x.value),
          geofence_ids: state.filters?.geofences?.map(x => x.value)
        };
    dispatch(fetchTreatments(reqParams));
  }

  function toggleModal(modal) {
    setState(prevState => ({ ...prevState, [modal]: !prevState[modal] }));
  }

  const debounceSearch = useCallback(
    debounce(cb => {
      cb();
    }, SEARCH_DEBOUNCE_DELAY),
    []
  );

  function onChange(value, field) {
    setState(prevState => ({ ...prevState, [field]: value }));
  }

  const handleKeyPress = charCode => {
    if (charCode === KEY_PRESS_CODE) {
      debounceSearch(getData);
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    dispatch(setSelectedFilters({}));
  };

  const setFilters = (pagination, filters) => {
    setFilteredInfo(filters);
    dispatch(setSelectedFilters(filters));
  };

  function selectRecords(selected) {
    setState(prevState => ({
      ...prevState,
      selectedIds: selected,
      selectAll: prevState.selectedIds?.length === selected?.length
    }));
  }

  function handleCSVChange(event) {
    setState(prevState => ({ ...prevState, uploading: true }));

    const reader = new FileReader();
    const isXLSX = event.target.files?.[0]?.name?.includes('.xlsx');

    if (!isXLSX) reader.readAsText(event.target.files[0]);

    const rABS = !!reader.readAsBinaryString;

    reader.onload = function (e) {
      let data = '';

      if (isXLSX) {
        const wb = XLSX.read(e.target.result, { type: rABS ? 'binary' : 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        data = XLSX.utils.sheet_to_csv(ws, { strip: true });
      } else {
        data = reader.result;
      }

      setState(prevState => ({ ...prevState, csvfile: data, uploading: false }));
    };

    if (isXLSX) {
      if (rABS) reader.readAsBinaryString(event.target.files[0]);
      else reader.readAsArrayBuffer(event.target.files[0]);
    }

    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }

  async function uploadCSV() {
    try {
      await axios.post('treatment/uploadCsv', { csv: state.csvfile });
      toggleModal('csv_modal');
      await getData();
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.DEFAULT_ERROR);
    }
  }

  const commonColumnProperties = {
    ellipsis: true,
    sortDirections: ['ascend', 'descend']
  };
  const columns = [
    {
      ...commonColumnProperties,
      title: 'Treatment ID',
      dataIndex: 'treatment_identifier',
      filteredValue: filteredInfo.treatment_identifier || null,
      fixed: 'left',
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.treatment_identifier, b.treatment_identifier)
    },
    {
      ...commonColumnProperties,
      title: 'Treatment Type',
      dataIndex: 'treatment_type_name',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: treatmentTypesSelectOptions
      },
      filteredValue: filteredInfo.treatment_type_name || null,
      filters: state.columnFilterOptions?.treatmentTypes,
      width: COLUMN_SIZE.LG,
      onFilter: (value, record) =>
        value === BLANK_FILTER_TEXT ? !record?.treatment_type_name : record?.treatment_type_name === value,
      sorter: (a, b) => sortStrings(a.treatment_type_name, b.treatment_type_name)
    },
    {
      ...commonColumnProperties,
      title: 'Treatment Date',
      dataIndex: 'treatment_date',
      editConfig: { type: EDIT_COMPONENT_TYPE.DATE_PICKER },
      filteredValue: filteredInfo.treatment_date || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      render: value => (value ? moment(value).format(DATE_FORMAT.DATE) : null),
      sorter: (a, b) => sortDates(a.treatment_date, b.treatment_date)
    },
    {
      ...commonColumnProperties,
      title: 'Symptoms',
      dataIndex: 'symptoms',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.symptoms || null,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.symptoms, b.symptoms)
    },
    {
      ...commonColumnProperties,
      title: 'Is Positive',
      dataIndex: 'is_positive',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: [
          { label: 'Yes', value: 1 },
          { label: 'No', value: 0 }
        ]
      },
      filteredValue: filteredInfo.is_positive || null,
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      width: COLUMN_SIZE.SM,
      onFilter: (value, record) => (value ? record.is_positive === 1 : !record.is_positive),
      render: value => (value === 1 ? 'Yes' : 'No'),
      sorter: (a, b) => sortNumbers(a.is_positive, b.is_positive)
    },
    {
      ...commonColumnProperties,
      title: 'Treatment',
      dataIndex: 'treatment',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.treatment || null,
      rules: [{ required: true, message: 'Required.' }],
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.treatment, b.treatment)
    },
    {
      ...commonColumnProperties,
      title: 'Medication',
      dataIndex: 'medication',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.medication || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.medication, b.medication)
    },
    {
      ...commonColumnProperties,
      title: 'Dosage UoM',
      dataIndex: 'dosage_uom',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.SELECT,
        options: treatmentUOMSelectOptions
      },
      filteredValue: filteredInfo.dosage_uom || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.dosage_uom, b.dosage_uom)
    },
    {
      ...commonColumnProperties,
      title: 'Dosage Amount',
      dataIndex: 'dosage_amount',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.dosage_amount || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortNumbers(a.dosage_amount, b.dosage_amount)
    },
    {
      ...commonColumnProperties,
      title: 'Vet',
      dataIndex: 'vet',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.vet || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortStrings(a.vet, b.vet)
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.comments || null,
      searchable: true,
      width: COLUMN_SIZE.XXL,
      sorter: (a, b) => sortStrings(a.comments, b.comments)
    },
    {
      ...commonColumnProperties,
      title: 'Cost',
      dataIndex: 'cost',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.cost || null,
      searchable: true,
      width: COLUMN_SIZE.MD,
      sorter: (a, b) => sortNumbers(a.cost, b.cost)
    },
    {
      ...commonColumnProperties,
      title: 'Repeat Date',
      dataIndex: 'repeat_date',
      editConfig: { type: EDIT_COMPONENT_TYPE.DATE_PICKER, minDate: 'treatment_date', allowClear: true },
      filteredValue: filteredInfo.repeat_date || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      render: value => (value ? moment(value).format(DATE_FORMAT.DATE) : null),
      sorter: (a, b) => sortDates(a.repeat_date, b.repeat_date)
    },
    {
      ...commonColumnProperties,
      title: 'Review Date',
      dataIndex: 'review_date',
      editConfig: { type: EDIT_COMPONENT_TYPE.DATE_PICKER, minDate: 'treatment_date', allowClear: true },
      filteredValue: filteredInfo.review_date || null,
      searchable: true,
      width: COLUMN_SIZE.LG,
      render: value => (value ? moment(value).format(DATE_FORMAT.DATE) : null),
      sorter: (a, b) => sortDates(a.repeat_date, b.repeat_date)
    },
    {
      ...commonColumnProperties,
      title: 'Result',
      dataIndex: 'result',
      editConfig: { type: EDIT_COMPONENT_TYPE.INPUT },
      editable: true,
      filteredValue: filteredInfo.result || null,
      searchable: true,
      width: COLUMN_SIZE.XL,
      sorter: (a, b) => sortStrings(a.result, b.result)
    }
  ];
  const livestockColumns = [
    {
      ...commonColumnProperties,
      title: 'Livestock ID',
      dataIndex: 'identifier',
      filteredValue: filteredInfo.identifier || null,
      fixed: 'left',
      searchable: true,
      width: COLUMN_SIZE.MD,
      render: (value, record) => <Link to={`animal/${record.id}`}>{record.identifier}</Link>,
      sorter: (a, b) => sortStrings(a.identifier, b.identifier)
    },
    {
      ...commonColumnProperties,
      title: 'Mgmt Tag ID',
      dataIndex: 'eartag_management_id',
      filteredValue: filteredInfo.eartag_management_id || null,
      searchable: true,
      width: COLUMN_SIZE.XL,
      sorter: (a, b) => sortStrings(a.eartag_management_id, b.eartag_management_id)
    },
    {
      ...commonColumnProperties,
      title: 'Sex',
      dataIndex: 'sex',
      filteredValue: filteredInfo.sex || null,
      filters: state.columnFilterOptions?.sex,
      width: COLUMN_SIZE.LG,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.sex : record?.sex === value),
      render: value => capitalize(value),
      sorter: (a, b) => sortStrings(a.sex, b.sex)
    },
    {
      ...commonColumnProperties,
      title: 'Colour',
      dataIndex: 'colour',
      editConfig: {
        type: EDIT_COMPONENT_TYPE.INPUT
      },
      filteredValue: filteredInfo.colour || null,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      width: COLUMN_SIZE.LG,
      sorter: (a, b) => sortStrings(a.colour, b.colour)
    },
    {
      ...commonColumnProperties,
      title: 'Breed',
      dataIndex: 'breed',
      filteredValue: filteredInfo.breed || null,
      filters: state.columnFilterOptions?.breeds,
      width: COLUMN_SIZE.XL,
      onFilter: (value, record) => (value === BLANK_FILTER_TEXT ? !record?.breed : record?.breed === value),
      sorter: (a, b) => sortStrings(a.breed, b.breed)
    }
  ];

  const tableColumns = params.id ? [...columns] : [...livestockColumns, ...columns];

  return (
    <>
      <div className={props.className}>
        <AppTable
          headerClass="py-2"
          searchPlaceholder="Search treatments"
          actions={actions}
          baseColumns={tableColumns}
          breadcrumb={props.breadcrumb}
          dataSource={treatments}
          searchable={props.searchable}
          title={props.title}
          loading={loading}
          searchDefaultValue={state.query}
          handleOnChange={setFilters}
          handleOnSelectRecords={selectRecords}
          handleSearchChange={e => onChange(e.target.value, 'query')}
          handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
          updateTableData={handleChangeTableData}
        />
      </div>

      <CreateTreatmentModal
        data={{ treatmentTypesSelectOptions, treatmentUOMSelectOptions }}
        isOpen={state.bulk_modal}
        handleCancel={() => toggleModal('bulk_modal')}
        handleConfirm={handleCreateTreatment}
      />

      <AppModal
        isOpen={state.delete_modal}
        confirmButtonColor="danger"
        confirmButtonText="Archive"
        title="Archive treatment"
        handleCancel={() => toggleModal('delete_modal')}
        handleConfirm={deleteRecords}>
        <div className="py-4">
          Are you sure to archive these {state.selectedIds?.length === 1 ? 'record' : 'records'}?
        </div>
      </AppModal>

      <UploadModal
        filename="treatment-template.csv"
        isOpen={state.csv_modal}
        onUpload={uploadCSV}
        handleCSVChange={event => handleCSVChange(event)}
        template={TREATMENT_CSV_TEMPLATE}
        onCancel={() => toggleModal('csv_modal')}
        description={
          <>
            <div>You can download the treatment list to input new treatments.</div>
            <div>Download and save the file and then choose this file to upload new treatments.</div>
          </>
        }
      />
    </>
  );
}

export default memo(TreatmentsTable);
