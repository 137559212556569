import { REDUCERS } from '../../constants/reducers';

const initialState = {
  list: [],
  unreadNotificationCount: 0,
  alerts: [],
  selectedAlerts: [],
  selectedFilters: {}
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.NOTIFICATION.SET_SELECTED_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        selectedFilters: action.value
      });
    }
    case REDUCERS.NOTIFICATION.SET_NOTIFICATION_STATE: {
      return Object.assign({}, state, {
        ...state,
        ...action.value
      });
    }
    case REDUCERS.NOTIFICATION.SET_NOTIFICATION_LIST: {
      return Object.assign({}, state, {
        list: [...action.value]
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { notification };
