import { REDUCERS } from '../../constants/reducers';

import { MeasurementService } from '../../services';

export const fetchDataRequest = () => ({
  type: REDUCERS.MEASUREMENT.FETCH_MEASUREMENTS_REQUEST
});

export const fetchDataSuccess = data => ({
  type: REDUCERS.MEASUREMENT.FETCH_MEASUREMENTS_SUCCESS,
  payload: data
});

export const fetchDataFailure = error => ({
  type: REDUCERS.MEASUREMENT.FETCH_MEASUREMENTS_FAILURE,
  payload: error
});

export const deleteRecords = ids => ({
  type: REDUCERS.MEASUREMENT.DELETE_MEASUREMENTS,
  payload: ids
});

export const setSelectedFilters = value => ({
  type: REDUCERS.ANIMAL.SET_SELECTED_FILTERS,
  value
});

export const updateDataFailure = error => ({
  type: REDUCERS.MEASUREMENT.UPDATE_MEASUREMENT_FAILURE,
  payload: error
});

export const createMeasurement = (id, data) => {
  return async dispatch => {
    try {
      await MeasurementService.createMeasurement(data);
      dispatch(fetchAnimalMeasurements(id, {}));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};

export const createMeasurements = data => {
  return async dispatch => {
    try {
      await MeasurementService.createMeasurements(data);
      dispatch(fetchMeasurements());
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};

export const updateMeasurement = (id, data) => {
  return async dispatch => {
    try {
      await MeasurementService.updateMeasurement(id, data);
      dispatch(fetchAnimalMeasurements(data.livestock_id));
    } catch (error) {
      dispatch(updateDataFailure(error.message));
    }
  };
};

export const updateMeasurementAndGetAll = (id, data) => {
  return async dispatch => {
    try {
      await MeasurementService.updateMeasurement(id, data);
      dispatch(fetchMeasurements());
    } catch (error) {
      dispatch(updateDataFailure(error.message));
    }
  };
};

export const deleteMeasurements = ids => {
  return async dispatch => {
    try {
      await MeasurementService.deleteMeasurements(ids);
      dispatch(deleteRecords(ids));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};

export const fetchMeasurements = params => {
  return async dispatch => {
    dispatch(fetchDataRequest());

    try {
      const { data } = await MeasurementService.fetchMeasurements(params);

      dispatch(fetchDataSuccess(data));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};

export const fetchAnimalMeasurements = (id, params) => {
  return async dispatch => {
    dispatch(fetchDataRequest());

    try {
      const { data } = await MeasurementService.fetchAnimalMeasurements(id, params);

      dispatch(fetchDataSuccess(data));
    } catch (error) {
      dispatch(fetchDataFailure(error.message));
    }
  };
};
