import React from 'react';
import { CompactPicker } from 'react-color';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Input, Row, Table } from 'reactstrap';

import axios from '../../axios';

import { getFarms } from '../../redux/actions/farm';

class LabelNewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      query: '',
      query_rules: '',
      selected_labels: [],
      selected_livestock: [],
      selected_rules: [],
      livestock_opts: [],
      rules_opts: [],
      colourName: '',
      linked_livestock: [],
      label: {
        name: '',
        id: null,
        color: '',
        animals: [],
        rules: []
      },
      new_label: {
        name: '',
        colour: '#000000',
        main_type: props.location.mainType || 'virtual',
        collection: props.location.collection || []
      },
      edit: false,
      dropdownOpen: false
    };

    this.getLivestock = this.getLivestock.bind(this);
    this.getGroups = this.getGroups.bind(this);
    this.getFarmsOptions = props.getFarms.bind(this);
    this.onNewLabelChangeLivestock = this.onNewLabelChangeLivestock.bind(this);
  }

  async componentDidMount() {
    this.getLivestock();
    this.getGroups();
    this.getFarmsOptions();
  }

  async getGroups() {
    const response = await axios.get('labels', {
      params: {
        farm_ids: []
      }
    });

    const groups = response.data
      .filter(item => {
        return item.main_type !== 'collection';
      })
      .map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    this.setState({
      groups
    });
  }

  async getLivestock() {
    let response = await axios.get('animals');
    let mapped_livestock = [];
    for (let live of response.data) {
      if (live.tag === null) {
        continue;
      }
      mapped_livestock.push({
        value: live.id,
        label: live.eartag_management_id
      });
    }
    this.setState({
      livestock_opts: mapped_livestock
    });
  }

  async saveLabel() {
    const payload = {
      ...this.state.new_label,
      collectionIds: (this.state.new_label.collection || []).map(item => item.value)
    };

    const response = await axios.post('/labels', payload);
    this.props.history.push('/group/' + response.data.id);
  }

  cancelLabel() {
    this.props.history.push('/groups');
  }

  onNewLabelChangeLivestock = selectedOption => {
    this.setState({
      selected_livestock: selectedOption,
      new_label: {
        ...this.state.new_label,
        animal_ids: selectedOption.map(x => x.value)
      }
    });
  };
  onNewLabelChange(value, field) {
    this.setState({
      new_label: {
        ...this.state.new_label,
        [field]: value
      }
    });
  }

  render() {
    const selectedTypeMapping = {
      virtual: {
        value: 'virtual',
        label: 'Virtual'
      },
      physical: {
        value: 'physical',
        label: 'Physical'
      },
      collection: {
        value: 'collection',
        label: 'Collection'
      }
    };
    return (
      <div>
        <Row className="">
          <Col xs="6" md="6" lg="6">
            <h4 className="">Group {this.state.label.name}</h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={() => this.props.history.push('/groups')}>
                  List of groups
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>Group {this.state.label.name}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <Button color="primary" className="float-right" onClick={() => this.saveLabel()}>
              Save
            </Button>
            <Button className="float-right outline" onClick={() => this.cancelLabel()}>
              Cancel
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12" className="global-table">
                  <Table>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <h4>Add new Group</h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Name</b>
                        </td>
                        <td>
                          <Input
                            name="name"
                            defaultValue={this.state.label.name}
                            onChange={e => this.onNewLabelChange(e.target.value, e.target.name)}></Input>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Type</b>
                        </td>
                        <td>
                          <Select
                            value={selectedTypeMapping[this.state.new_label.main_type]}
                            onChange={e => {
                              this.setState({
                                new_label: {
                                  ...this.state.new_label,
                                  main_type: e.value,
                                  collection: []
                                }
                              });
                            }}
                            options={[
                              {
                                value: 'virtual',
                                label: 'Virtual'
                              },
                              {
                                value: 'physical',
                                label: 'Physical'
                              },
                              {
                                value: 'collection',
                                label: 'Collection'
                              }
                            ]}
                          />
                        </td>
                      </tr>
                      {this.state.new_label.main_type === 'collection' && (
                        <tr>
                          <td className="v-align-middle">
                            <b>Groups</b>
                          </td>
                          <td>
                            <Select
                              value={this.state.new_label.collection}
                              onChange={group => {
                                this.onNewLabelChange([...group], 'collection');
                              }}
                              options={this.state.groups}
                              isMulti={true}
                              isSearchable={true}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="v-align-middle">
                          <b>Group Color</b>
                        </td>
                        <td>
                          <CompactPicker
                            name="colour"
                            color={this.state.new_label.colour}
                            onChange={e => this.onNewLabelChange(e.hex, 'colour')}></CompactPicker>
                        </td>
                      </tr>
                      <tr>
                        <td className="v-align-middle">
                          <b>Farm</b>
                        </td>
                        <td>
                          <Select
                            value={this.state.geofence}
                            onChange={e => this.onNewLabelChange(e.value, 'farm_id')}
                            options={this.props?.farm?.options}
                            isSearchable={true}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12">
            <Button color="primary" className="float-right" onClick={() => this.saveLabel()}>
              Save
            </Button>
            <Button className="float-right outline" onClick={() => this.cancelLabel()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(
  state => ({
    farm: state?.farm,
    location: state?.location
  }),
  dispatch => ({
    getFarms: () => {
      dispatch(getFarms()).then(farms => {
        return farms;
      });
    }
  })
)(LabelNewPage);
