import { REDUCERS } from '../../constants/reducers';

export const setCalendarState = value => ({
  type: REDUCERS.CALENDAR.SET_CALENDAR_STATE,
  value
});

export const setCurrentSelectedDay = value => ({
  type: REDUCERS.CALENDAR.SET_CURRENT_DATE,
  value
});

export const incrementNextCountCalendar = value => ({
  type: REDUCERS.CALENDAR.INCREMENT_NEXT_COUNT_CALENDAR,
  value
});

export const incrementPrevCountCalendar = value => ({
  type: REDUCERS.CALENDAR.INCREMENT_PREV_COUNT_CALENDAR,
  value
});
