import { REDUCERS } from '../../constants/reducers';

const initialState = {
  data: null,
  error: null,
  loading: false,
  selectedFilters: {},
  sireAry: [],
  calfAry: []
};

const animal = (state = initialState, action) => {
  switch (action.type) {
    case REDUCERS.ANIMAL.FETCH_ANIMAL_REQUEST:
      return { ...state, loading: true, data: null, error: null };
    case REDUCERS.ANIMAL.FETCH_ANIMAL_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case REDUCERS.ANIMAL.FETCH_ANIMAL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case REDUCERS.ANIMAL.SET_SELECTED_FILTERS: {
      return Object.assign({}, state, {
        ...state,
        selectedFilters: action.value
      });
    }
    case REDUCERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export { animal };
