import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import debounce from 'lodash.debounce';

import { capitalize } from '../../../helpers/common';
import { sortNumbers, sortStrings } from '../../../helpers/filter';

import { CONNECTION_STATUSES, KEY_PRESS_CODE, SEARCH_DEBOUNCE_DELAY } from '../../../constants/common';
import messages from '../../../constants/messages';

import { fetchGateways } from '../../../redux/actions/gateway';

import AppTable from '../../../components/AppTable';
import { COLUMN_SIZE } from '../../../components/AppTable/constants';
import { errorToastHandler } from '../../../components/action_notifier';
import StatusIndicator from '../../../components/statusIndicator';

import { SocketContext } from '../../../context/socket';

function GatewaysTable() {
  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();
  const { gateways, gatewaysLoading } = useSelector(state => state.gateway);
  const [state, setState] = useState({
    gatewaysQuery: '',
    ws: null
  });

  useEffect(() => {
    dispatch(fetchGateways());
  }, [dispatch]);

  useEffect(() => {
    if (socket && !state.ws) {
      setState(prevState => ({ ...prevState, ws: socket }));

      socket.on('gatewayStatus', data => {
        setState(prevState => ({
          ...prevState,
          gateways: gateways.map(item => {
            let status = item.status;

            if (data.ids.includes(item.id)) {
              status = data.status;
            }

            return { ...item, status };
          })
        }));
      });
    }

    return () => socket.off('gatewayStatus');
  }, [socket]);

  const debounceSearch = useCallback(
    debounce(cb => {
      cb();
    }, SEARCH_DEBOUNCE_DELAY),
    []
  );

  const onChange = (value, field) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleKeyPress = charCode => {
    if (charCode === KEY_PRESS_CODE) debounceSearch(getGateways);
  };

  const getGateways = async () => {
    try {
      dispatch(fetchGateways({ query: state.gatewaysQuery }));
    } catch (error) {
      errorToastHandler(error?.response?.data?.message || messages.DEFAULT_ERROR);
    }
  };

  const columns = [
    {
      title: 'Gateway ID',
      dataIndex: 'identifier',
      fixed: 'left',
      ellipsis: true,
      width: COLUMN_SIZE.MD,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.identifier, b.identifier),
      render: (value, record) => <Link to={`gateway/${record.id}`}>{record.identifier}</Link>
    },
    {
      title: 'Gateway Name',
      dataIndex: 'name',
      ellipsis: true,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortStrings(a.name, b.name),
      render: (value, record) => <Link to={`gateway/${record.id}`}>{value}</Link>
    },
    {
      title: 'Connection Status',
      dataIndex: 'status',
      ellipsis: true,
      width: COLUMN_SIZE.XL,
      sortDirections: ['ascend', 'descend'],
      filters: CONNECTION_STATUSES,
      onFilter: (value, record) => record?.status.startsWith(value),
      sorter: (a, b) => sortStrings(a?.status, b?.status),
      render: (value, record) => {
        const status = record?.status;
        const id = record?.id;

        return (
          record && (
            <div className="d-flex align-items-center">
              <StatusIndicator key={id} status={status} />
              {capitalize(status)}
            </div>
          )
        );
      }
    },
    {
      title: 'Connected Kraal Tags',
      dataIndex: 'connected_tags_count',
      ellipsis: true,
      searchable: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortNumbers(a.connected_tags_count, b.connected_tags_count)
    }
  ];

  return (
    <AppTable
      headerClass="py-2"
      baseColumns={columns}
      dataSource={gateways}
      filterable={false}
      loading={gatewaysLoading}
      searchDefaultValue={state.gatewaysQuery}
      searchPlaceholder="Search Kraal Gateways"
      searchable={true}
      handleSearchChange={e => onChange(e.target.value, 'gatewaysQuery')}
      handleSearchKeyPress={() => handleKeyPress(KEY_PRESS_CODE)}
    />
  );
}

export default memo(GatewaysTable);
