import { Component } from 'react';
import { CompactPicker } from 'react-color';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap';

import classnames from 'classnames';

import axios from '../../axios';

import { capitalize } from '../../helpers/common';

import Alerts from '../../components/Alerts';
import PrevNextButtons from '../../components/PrevNextButtons';
import Map from '../../components/map_sites';

import AppDropdown from '../ui-components/dropdown';

import zoom from '../../assets/images/zoom.png';

class Site extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      activeTab: '1',
      color: '#3388ff',
      delete_modal: false,
      edit: false,
      farms_org: [],
      filters: {
        farms: [],
        geofences: []
      },
      isFullScreen: false,
      new: this.props.match.params.id === 'new',
      query: '',
      selected_farm: {},
      selected_geofences: [],
      selected_sites: [],
      selected_type: {},
      site_types: [],
      site: {
        identifier: '',
        name: '',
        geofences: [],
        rules: [],
        geo_json: {}
      }
    };

    this.getSiteTypes = this.getSiteTypes.bind(this);
    this.getSites = this.getSites.bind(this);
    this.initActions = this.initActions.bind(this);
    this.onNewFence = this.onNewFence.bind(this);
    this.onSiteChangeGeofence = this.onSiteChangeGeofence.bind(this);
    this.onSiteChangeType = this.onSiteChangeType.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    this.getSiteTypes();

    const response = await axios.get('farms?with_details=true');
    const farms = response.data.map(f => ({
      value: f.id,
      label: f.name,
      meta: {
        address: f.address.full_address,
        master_geofences: f.master_geofence,
        features: f.geofences,
        sites: f.sites
      }
    }));

    if (farms.length) {
      const farm = farms[0];
      this.setState(
        {
          ...this.state,
          farms: farms,
          farms_org: response.data,
          selected_farm: farm,
          site: {
            ...this.state.site,
            farm_id: farm.value,
            address: farm.meta.address,
            features: farm.meta.features
          }
        },
        () => this.getSites()
      );
    } else {
      this.setState(
        {
          ...this.state,
          farms: farms,
          farms_org: response.data
        },
        () => this.getSites()
      );
    }

    this.initActions();
  }

  initActions() {
    this.setState({
      actions: [
        {
          label: 'Edit site',
          isVisible: () => true,
          handler: () => this.setState({ edit: true })
        },
        {
          label: 'Archive site',
          isVisible: () => true,
          handler: () => this.toggleModal('delete_modal')
        }
      ]
    });
  }

  toggleFullscreen() {
    this.setState(state => ({ ...state, isFullScreen: !this.state.isFullScreen }));
  }

  onChange = (value, field) => {
    if (field === 'query') this.getTags();

    this.setState(state => ({ ...state, [field]: value }));
  };

  async getSites(id = null) {
    if (this.state.new) return;

    const response = await axios.get('sites/' + (id !== null ? id : this.props.match.params.id), {
      query: this.state.query
    });

    if (response.status === 200) {
      this.setState({
        site: response.data,
        color: response.data.color,
        selected_farm: this.state.farms.filter(x => x.value === response.data.farm_id)[0],
        selected_geofences: response.data.geofences.map(x => {
          return { label: x.name, value: x.id };
        }),
        selected_type: this.state.site_types.filter(x => x.value === response.data.type)
      });
    }
  }

  async getSiteTypes() {
    const response = await axios.get('/sites/types');

    if (response.status === 200) this.setState({ site_types: response.data });
  }

  async saveSite() {
    if (this.state.new) {
      const response = await axios.post('sites', {
        ...this.state.site,
        color: this.state.color
      });

      this.setState({ new: false });
      await this.getSites(response.data.id);
      this.props.history.push('/site/' + response.data.id);
    } else {
      const response = await axios.put('sites/' + this.props.match.params.id, {
        ...this.state.site,
        color: this.state.color
      });

      this.setState({ edit: false });
      await this.getSites(response.data.id);
      setTimeout(() => {
        window.location.href = '/site/' + response.data.id;
      }, 300);
    }
  }

  async deleteSite() {
    const response = await axios.delete('sites/' + this.props.match.params.id);

    if (response.status === 200) this.props.history.push('/sites');
  }

  onSiteChange(value, field) {
    if (field === 'value') {
      this.setState({ selected_type: value });
      value = value.value;
    }

    this.setState({ site: { ...this.state.site, [field]: value } });
  }

  onSiteChangeType(value) {
    this.setState({ selected_type: value, site: { ...this.state.site, type: value.value } });
  }

  onSiteChangeGeofence(value) {
    this.setState({
      selected_geofences: value,
      site: {
        ...this.state.site,
        geofence_ids: value.map(x => x.value)
      }
    });
  }

  onNewFence(val) {
    this.setState({ site: { ...this.state.site, geo_json: val } });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  toggleModal(modal) {
    this.setState(state => ({ ...state, [modal]: !state[modal] }));
  }

  multiselectChange = sle => {
    this.setState({
      ...this.state,
      selected_farm: sle,
      site: {
        ...this.state.site,
        farm_id: sle.value,
        address: sle.meta.address,
        features: sle.meta.features
      },
      editing: true
    });
    this.forceUpdate();
  };

  render() {
    const zoomImg = {
      backgroundImage: 'url(' + zoom + ')'
    };

    return (
      <>
        <Row>
          <Col xs="6" md="6" lg="6">
            <h4>
              {this.state.edit ? 'Edit Site ' + this.state.site.name : ''} {this.state.new ? 'Create New Site' : ''}{' '}
              {!this.state.edit && !this.state.new && 'Site ' + this.state.site.name}
            </h4>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="#" onClick={() => this.props.history.push('/sites')}>
                  List of Sites
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                {this.state.edit ? 'Edit Site' : ''} {this.state.new ? 'Create New Site' : ''}{' '}
                {!this.state.edit && !this.state.new && 'Site ' + this.state.site.name}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xs="6" md="6" lg="6">
            <PrevNextButtons
              nextId={this.state.site.next_id}
              prevId={this.state.site.prev_id}
              handlePrev={() => this.props.history.push(`/site/${this.state.site.prev_id}`)}
              handleNext={() => this.props.history.push(`/site/${this.state.site.next_id}`)}
            />
            {this.state.new && (
              <div>
                <Button color="primary" className="float-right" onClick={() => this.saveSite()}>
                  Save
                </Button>
                <Button className="float-right outline" onClick={() => this.props.history.push('/sites')}>
                  Cancel
                </Button>
              </div>
            )}
            {this.state.edit && (
              <div>
                <Button color="primary" className="float-right" onClick={() => this.saveSite()}>
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() => this.setState({ edit: false }, () => this.getSites())}>
                  Cancel
                </Button>
              </div>
            )}
            {!this.state.edit && !this.state.new && (
              <AppDropdown
                className="d-flex justify-content-end"
                label="Actions"
                items={this.state.actions.filter(item => item.isVisible())}
                handleClick={action => action.handler()}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" lg="12">
            <Card>
              {(this.state.new || this.state.edit) && (
                <Row className="">
                  <Col lg="12" style={{ zIndex: 9 }} className="global-table">
                    <Table className="no-border mt-2 mb-2">
                      <tbody>
                        <tr>
                          <td className="v-align-middle">
                            <b>Farm</b>
                          </td>
                          <td>
                            <Select
                              name="farm"
                              onChange={this.multiselectChange}
                              value={this.state.selected_farm}
                              options={this.state.farms}
                              defaultValue={this.state.selected_farm.value ? this.state.selected_farm : null}
                              isMulti={false}></Select>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  xs="12"
                  md="12"
                  lg="12"
                  className={`
                  ${this.state.isFullScreen ? 'rg-full-screen icon-position' : ''} 
                  ${this.state.editing ? '' : 'rg-no-space'}
                  `}>
                  <Map
                    new={this.state.new || this.state.edit}
                    editing={this.state.edit || this.state.new}
                    site={{
                      geo_json: this.state.site.geo_json,
                      features: this.state.site.features
                    }}
                    color={this.state.color}
                    features={this.state.site.features}
                    onEdit={this.onNewFence}
                    onCreate={this.onNewFence}
                    farm={this.state.selected_farm}
                    isFullScreen={this.state.isFullScreen}></Map>
                  <div className="full-screen-icon text-center" onClick={this.toggleFullscreen} style={zoomImg}></div>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col xs="12" md="12" lg="12" className="global-table">
                  {(this.state.new || this.state.edit) && (
                    <Table>
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <h4>Site {this.state.site.name} Details</h4>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Name</b>
                          </td>
                          <td>
                            <Input
                              name="name"
                              defaultValue={this.state.site.name}
                              onChange={e => this.onSiteChange(e.target.value, e.target.name)}></Input>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Geofences</b>
                          </td>
                          <td>
                            <Select
                              name="type"
                              defaultValue={this.state.selected_geofences}
                              options={
                                this.state.selected_farm.meta
                                  ? this.state.selected_farm.meta.features.map(x => {
                                      return { label: x.name, value: x.id };
                                    })
                                  : []
                              }
                              onChange={this.onSiteChangeGeofence}
                              isMulti={true}
                              isSearchable={true}></Select>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Type</b>
                          </td>
                          <td>
                            <Select
                              name="type"
                              defaultValue={this.state.selected_type}
                              options={this.state.site_types}
                              onChange={this.onSiteChangeType}></Select>
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Color</b>
                          </td>
                          <td>
                            <CompactPicker
                              name="colour"
                              color={this.state.color}
                              onChange={e => this.setState({ ...this.state, color: e.hex })}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="v-align-middle">
                            <b>Site Description</b>
                          </td>
                          <td>
                            <Input
                              name="description"
                              defaultValue={this.state.site.description}
                              onChange={e => this.onSiteChange(e.target.value, e.target.name)}></Input>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}

                  {!this.state.new && !this.state.edit && (
                    <Table>
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <h4>Site {this.state.site.name} Details</h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site ID</b>
                          </td>
                          <td>{this.state.site.identifier}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site Name</b>
                          </td>
                          <td>{this.state.site.name}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site Type</b>
                          </td>
                          <td>{capitalize(this.state.site.type)}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site Geofences</b>
                          </td>
                          <td>{this.state.site.geofences.map(x => x.name).join(', ') || 'No geofences'}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Site Description</b>
                          </td>
                          <td>{this.state.site.description}</td>
                        </tr>
                        {['trial', 'advanced', 'basic'].includes(this.props.subscription.myPlan.type) ? null : (
                          <tr>
                            <td>
                              <b>Site Rules</b>
                            </td>
                            <td>{this.state.site.rules.map(x => x.name).join(', ') || 'No rules'}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col
            lg="12"
            md="12"
            sm="12"
            className={this.state.isFullScreen && (this.state.edit || this.state.new) ? 'rg-full-screen-btn' : ''}>
            {this.state.edit && (
              <div>
                <Button color="primary" className="float-right" onClick={() => this.saveSite()}>
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() => this.setState({ edit: false }, () => this.getSites())}>
                  Cancel
                </Button>
              </div>
            )}
            {this.state.new && (
              <div>
                <Button color="primary" className="float-right" onClick={() => this.saveSite()}>
                  Save
                </Button>
                <Button
                  className="float-right outline"
                  onClick={() => {
                    this.props.history.push('/sites');
                  }}>
                  Cancel
                </Button>
              </div>
            )}
          </Col>
        </Row>

        {this.props.match.params.id !== 'new' && (
          <Card>
            <Row className="pad-10 pb-0">
              <Col sm="6" className="mb-0">
                <h5>History</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <Nav tabs className="fancy-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => this.toggle('1')}>
                      Notifications
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" lg="12">
                <TabContent className="no-bg" activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Alerts type="full" query={{ site_ids: [this.props.match.params.id] }}></Alerts>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Card>
        )}

        <Modal isOpen={this.state.delete_modal} className={this.props.className}>
          <ModalBody>
            <br />
            <h5 className="text-center">
              <b>Archive Site</b>
            </h5>
            <br />
            <br />
            <br />
            Are you sure you want to archive this site? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleModal('delete_modal')}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={() => this.deleteSite()}>
              Archive
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default connect(state => state)(Site);
