import { memo } from 'react';

import { Tooltip } from 'antd';

function StatusIndicator({ status }) {
  return (
    <Tooltip title={status}>
      <div className={`statusIndicator ${status}`}></div>
    </Tooltip>
  );
}

export default memo(StatusIndicator);
